import React from "react";
import { Example } from "./Example";
import FirstIconHomepage from "assets/images/1st_icon_homepage.svg";
import ThirdIconHomepage from "assets/images/3rd_icon_homepage.svg";
import SecondIconHomepage from "assets/images/2nd_icon_homepage.svg";


import "./Example.scss";

export const ExampleList = () => {
    return (
        <ul className="examplesNavList">

            <li>
                <Example text={'Get smart on general industry knowledge'}
                    subText={'Leverage past interviews with industry experts to identify major competitors, understand customer sentiments, market trends, potential risks & disruptions'}
                    imgPath={FirstIconHomepage} />
            </li>
            <li>
                <Example text={'Understand specific company perspectives'}
                    subText={'Get access to insights & summaries to develop and check key hypothesis, identify major gaps in logic.'}
                    imgPath={SecondIconHomepage} />
            </li>

            <li>
                <Example text={'Create an interview guide'}
                    subText={'Prepare for expert interviews by taking reference from past interviews. Search View and Download past interviews at no additional cost'}
                    imgPath={ThirdIconHomepage} />
            </li>

        </ul>
    );
};
