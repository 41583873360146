import PAGE_ANALYTICS from "utils/analytics/page_analytics";
import { DTMRULE, TOOLNAME } from "utils/analytics/analytics_constants";
import { getStaffEmailID } from "utils/auth/auth";
export default {
  /**
   *
   * @param {*} Object - having adobeAnalyticsData
   * @param {*} string - having dtmRule
   */

  async sendEventData(adobeAnalyticsData, dtmRule = DTMRULE.KB, toolName = TOOLNAME?.Transcript_Library) {
    const staffSystemID = await getStaffEmailID();
    const pageInfoObj = { headerInfo: { toolName: toolName } };
    adobeAnalyticsData = { ...adobeAnalyticsData, page: { ...adobeAnalyticsData['page'], ...pageInfoObj } };
    adobeAnalyticsData.page.pageInfo.staffSystemID = staffSystemID;
    window.digitalData = adobeAnalyticsData;
    PAGE_ANALYTICS.track(dtmRule);
  },
};
