//////////// Importing Constant Files ////////
// import LABELS from "labels";

// Global constants

const globalConstants = {
  ACTIONS: {
    GLOBAL_SAGA_FAILURE: "GLOBAL_SAGA_FAILURE",
    GLOBAL_MODULE_FAILURE: "GLOBAL_MODULE_FAILURE"
  },
  TAB: "tab",
  TAB1: "tab1",
  TAB2: "tab2",
  TAB3: "tab3",
  ENTITLEMENTS: {
    TRANSCRIPT_LIBRARY: "TRANSCRIPT_LIBRARY",
    SEARCH: "SEARCH",
    CHAT: "CHAT",
    DOWNLOAD: "DOWNLOAD",
    PRODUCTID: "PRODUCTID",
  },
  API_STATUS: {
    START: "Start",
    PENDING: "Pending",
    COMPLETED: "Completed",
    ERROR: "Error"
  },
  ERROR_401: "401",
  ERROR_IE: "ie11",
  ERROR_ENTITLEMENT_API: "entitlementapi",
  DOCVIZ_EXTNS: [
    "doc",
    "docx",
    "pdf",
    "ppt",
    "pptx"
  ],
  TBDBS: {
    SEARCH_SUBJECT: "SEARCH_SUBJECT",
    SEARCH_FPA_IPA_INTERNAL: "SEARCH_FPA_IPA_INTERNAL",
    FPA: "FPA",
    IPA: "IPA",
    BCG_INTERNAL: "BCG_INTERNAL"
  },
}

export default globalConstants;