import React, { useRef, useState, useEffect } from "react";
import { Answer, AnswerError } from "../../components/Answer";
import { ExampleList } from "../../components/Example";
import { QuestionInput } from "../../components/QuestionInput";
import { connect } from "react-redux";
import { UserChatMessage } from "../../components/UserChatMessage";
import { getActiveUserSession } from "redux/actions/usersession/usersession.actions";
import { selectors as userSessionSelector } from "redux/reducers/usersession/usersession_reducer";
import globesmall from "assets/images/globe_small.svg";

// import { SettingsButton } from "../../components/SettingsButton";
// import { ClearChatButton } from "../../components/ClearChatButton";
import LABELS from "labels";
import { LoadingDotsFlashing } from "components/Loading/LoadingDotsFlashing";
import { Layout } from "containers/Layout";
import { useDispatch, useSelector } from "react-redux";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import { selectors as suggestedQuerySelector } from "redux/reducers/suggested_query/suggestedquery_reducer";
import { SUGGESTED_QUERY_DATA, RESPONSE_DATA } from "redux/constants";

import Image from "components/shared/Image";
import { Taxonomy } from "containers/Taxonomy";
import logoIcon from "assets/images/Empty_Chat_Sandy_Edu_cropped.png";

import "./ManualReview.scss";
import { UpdatePageUrlWithoutRefresh } from "utils/helpers/helpers";

import {
    DTMRULE,
    PAGES,
    PRIMARY_CATEGORY
} from "utils/analytics/analytics_constants";
const {
    SUGGESTED_QUERY
} = SUGGESTED_QUERY_DATA;

const {
    QUERY_RESPONSE
} = RESPONSE_DATA;

const ManualReviewContainer = ({
    activeUserSessionId,
    history,
    getCurrentUserSession,
    match: {
        params: { sessionId },
    }
}) => {
    const {
        CHAT_EMPTY_TITLE,
        CHAT_EMPTY_SUBTITLE,
        INPUT_EMPTY_HEADING,
        INPUT_HEADING,
        CLOSE
    } = LABELS;
    const dispatch = useDispatch();
    const isResponseLoading = useSelector(responseSelector.getLoading);
    const queryResponse = useSelector(responseSelector.getResponse);
    const queryResponseError = useSelector(responseSelector.getError)

    const suggestedQueryResponse = useSelector(suggestedQuerySelector.getResponse);

    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState("");
    const [retrieveCount, setRetrieveCount] = useState(3);
    const [useSemanticRanker, setUseSemanticRanker] = useState(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState(false);
    // const [excludeCategory, setExcludeCategory] = useState("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState(false);

    const lastQuestionRef = useRef("");
    const chatMessageStreamEnd = useRef(null);

    const [activeCitation, setActiveCitation] = useState();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState(0);

    const makeApiRequest = (question) => {

        lastQuestionRef.current = question;

        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        const chatSessionId = sessionId ? sessionId : 'weif2-24hfe-2f4hei-2eif2-op9vd';
        dispatch({ type: QUERY_RESPONSE, payload: { chatSessionId, question: question } });
    };
    // const clearChat = () => {
    //     lastQuestionRef.current = "";
    //     error && setError(undefined);
    //     setActiveCitation(undefined);
    //     setActiveAnalysisPanelTab(undefined);
    //     setAnswers([]);
    // };

    if (!!activeUserSessionId && !sessionId) {
        UpdatePageUrlWithoutRefresh('/' + activeUserSessionId, history)
    }

    useEffect(() => {
        if (!activeUserSessionId && !sessionId) {
            getCurrentUserSession();
        }
    }, []);

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isResponseLoading]);
    useEffect(() => {
        dispatch({ type: SUGGESTED_QUERY, payload: {} })
    }, []);


    const onPromptTemplateChange = (_ev, newValue) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev, newValue) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onUseSemanticRankerChange = (_ev, checked) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev, checked) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev, newValue) => {
        // setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev, checked) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onExampleClicked = (example) => {
        makeApiRequest(example);
    };

    const onToggleTab = (tab, index) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const getValidationError = (isInternal, errorState, searchDiscoveryTags) => {
        if ((errorState && errorState && errorState['searchDiscoveryTags']) || this.getValidationMessage(isInternal, searchDiscoveryTags)) {
            return true;
        }
        return false;
    }
    const isInternal = true;
    const id = 123;
    const taxonomyAnalyticsCallback = (subId, pageId, triggerName, screenName, typeAheadObj) => {
        let analyticsObj = {
            page: {
                pageInfo: {
                    pageName: PAGES.KP_PAGES,
                },
                category: {
                    primaryCategory: PRIMARY_CATEGORY.KP
                }
            },
            collection: {
                trigger: triggerName,
                screenName: screenName,
                subTaxonomyID: subId
            },
            KCpage: {
                id: pageId
            }
        };

        if (typeAheadObj) {
            const {
                searchTerm, selectedOptionValue, selectedOptionRank
            } = typeAheadObj;
            analyticsObj["search"] = {
                term: searchTerm,
                typeHeadOptionText: selectedOptionValue,
                typeHeadOptionRank: selectedOptionRank
            }
        }
        //ANALYTICS.kc.sendEventData(analyticsObj, DTMRULE.KP);
    }
    return (
        <Layout id="chat" layoutClass="layout__my-navigator">
            <div style={{ marginLeft: "100px" }}>
                <Taxonomy
                    key="123"
                    label={"SEARCH_TAGS_HEADING"}
                    placeholder={"SEARCH_TAGS_HEADING"}
                    modalHeader={"SECTION_SEARCH_HEADING"}
                    product={"KP_PRODUCT_NAME"}
                    maxLimit={5}
                    taxonomyName={[
                        { label: "Subject", key: "SUBJECT" }
                    ]}
                    onChange={(data) => {
                        //handleChange(data[0], "searchDiscoveryTags", STEP3);
                    }}
                    preFillData={[[
                        {
                            "id": "7b6e6fe0-7aa9-46b5-8aaa-cac1097d4151",
                            "name": "Automotive and Mobility",
                            "isOwner": false,
                            "isPARecommended": false,
                            "paRecommendedDate": "",
                            "isInternal": false
                        },
                        {
                            "id": "8681d82c-e2b6-43db-b169-c94a4ebdeddf",
                            "name": "Agile",
                            "isOwner": false,
                            "isPARecommended": false,
                            "paRecommendedDate": "",
                            "isInternal": false
                        },
                        {
                            "id": "fcf6d597-2886-4bdc-aaf8-4d9d22b1892a",
                            "name": "Accounting and Finance",
                            "isOwner": false,
                            "isPARecommended": false,
                            "paRecommendedDate": "",
                            "isInternal": false
                        }
                    ]]}
                    className={'2'}
                    openModalCallback={() => { }}
                    clickHandlerCallback={(subId) => { }}
                    deleteSelectedCallBack={(subId) => { }}
                    submitHandlerCallback={() => { }}
                    typeAheadHandlerCallback={(subId, searchTerm, selectedOptionValue, selectedOptionRank) => { }}
                    responseTypeSearchKeyName={{
                        id: "id",
                        name: "name",
                    }}
                    isShowParentTagsTooltip
                    typeSearchplaceholder={"TYPESEARCH_PLACEHOLDER"}
                    characterCount={2}
                    taxonomyTypeSearchName={[
                        { searchKey: "SEARCH_SUBJECT" }
                    ]}
                    tooltipHtml={
                        <>
                            <p>{"KP_SUBJECT_1"}</p>
                            <p>{"KP_SUBJECT_2"}</p>
                        </>
                    }
                    // suggestions={{
                    //     tags: isInternal ? [] : subjectSuggestions,
                    //     heading: SUBJECT_SUGGESTION_HEADING_BY_FILEUPLOAD,
                    //     modalHeading: SUBJECT_SUGGESTION_MODALHEADING,
                    //     showThumbs: true,
                    //     thumbsUpdownCallback: this.suggestionThumbsUpdownCallback
                    //     // maxLimitLabel: MAXIMUM_TAGS_SELECTED,
                    //     // clickCallback: this.handleSuggestionsClick
                    // }}
                    // suggestionResponseKeyName={{
                    //     id: "label_guid",
                    //     name: "label_value",
                    //     type: "label_type"
                    // }}
                    validationErrorMessage={null}
                />
            </div>
        </Layout >
    );
};

const mapStateToProps = (state) => ({
    activeUserSessionId: userSessionSelector.getActiveUserSession(state),
});

const mapDispatchToProps = (dispatch) => ({
    getCurrentUserSession: () => dispatch(getActiveUserSession()),
});


export const ManualReview = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManualReviewContainer);

export { ManualReviewContainer };
// export default Chat;
