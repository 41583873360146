import { getStaffEmailID, isUserAuthenticated } from "utils/auth/auth";
import { datadogRum } from '@datadog/browser-rum';
import { TOOLNAME } from "utils/analytics/analytics_constants";
import _ from 'lodash';

export default {
  async initialize({ pageName, previousPageName, category, trackPageLoad, toolName = TOOLNAME?.Transcript_Library }) {
    let staffSystemID = "unknown";
    if (isUserAuthenticated()) {
      staffSystemID = await getStaffEmailID();
    }
    const data = {
      page: {
        pageInfo: {
          pageName: pageName, //eVar1
          previousPageName: previousPageName, //eVar2
          staffSystemID: staffSystemID, //eVar23,
          pageURL: window.location.href, //eVar3
        },
        headerInfo: {
          toolName: toolName, //eVar80
        },
        category: {
          primaryCategory: category, //eVar50
        },
      },
    };

    window.digitalData = data;
    window.analyticsObj = data;

    if (trackPageLoad) {
      this.track("Global_Page_Load");
    }
  },

  track(
    eventName,
    options = {
      integrations: {
        Adobe: true,
        DataDog: false,
      },
    }
  ) {
    try {
      if (options.integrations.Adobe) {
        this.waitForElement(window._satellite, eventName);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
    try {
      if (options.integrations.DataDog) {
        datadogRum.addUserAction(eventName, window.digitalData);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  },

  setGlobalProps(adobeAnalyticsData) {
    const adobe = {
      collection: {
        trigger: "",
        ID: "",
        screenName: "",
      },
      suggestedTopicID: "",
      KCpage: {
        id: ""
      }
    };
    if (Object.keys(adobeAnalyticsData).length) {
      Object.assign(adobe, adobeAnalyticsData);
    }


    if (adobe) {
      const initializedData = { ...window.analyticsObj };
      window.digitalData = _.merge(initializedData, adobe);

    }
  },

  waitForElement(satellite = window._satellite, eventName = "Global_Page_Load") {
    if (typeof satellite !== "undefined") {
      satellite.track(eventName);
    }
    else {
      setTimeout(this.waitForElement, 500, satellite, eventName);
    }
  }
};
