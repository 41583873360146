// PRIMARY CATEGORY AS NEEDED BY ANALYTICS
const PRIMARY_CATEGORY = {
  Transcript_Library: "Transcript_Library"
}; // to be send in page.category.primaryCategory, right now it is same for all
const DISPLAY_MODE = {
  DOCVIZ_OVERLAY: "docviz overlay"
}
const DTMRULE = {
  INTERNAL_SEARCH: "Internal_Search",
  PREVIEW_DOCUMENT: "Preview_Document",
  CLOSE_DOCUMENT: "Close_Document",
  TOOLS_SEARCH_LINK_CLICKS: "Tools_Search_Link_Clicks",
  ICON_ACTION_CLICKS: "Icon_Action_Clicks",
  BUTTON_LINK_CLICKS: "Button_Link_Clicks",
  FILTER_CLICKS: "Filter_Clicks"
}

const
  PAGES = {
    Transcript_Library_Keyword_Search: "Transcript_Library_Keyword_Search",
    Transcript_Library_Chatbot: "Transcript_Library_Chatbot",
    Detailed_Transcript_View: "Transcript_Library_Detailed_View"
  };
const TOOLNAME = {
  Transcript_Library: "Transcript Library"
};


export { PRIMARY_CATEGORY, PAGES, DTMRULE, TOOLNAME };
