import { doAxiosRequest, cancelAxiosRequest } from 'config-axios';
// import store from '../store';
import { selectors as globalSelectors } from "redux/reducers/global/global_reducer";
import { getUserId } from "utils/auth/auth";

import CONFIG from 'config';
import { PEOPLE_DATA } from 'redux/constants';
// import { TYPEAHEAD_DATA } from 'redux/constants';
import * as utils from '@kdshared/okta-utils';
import { isObjEmpty } from 'utils/helpers/helpers';


const {
  PEOPLE_API_QUERY_PARAMS: {
    DEFAULT: {
      LIMIT, OFFSET, REQUEST_DATA
    }
  }
} = CONFIG;

const getDataByIDs = (ids, optionalParams) => {
  let temp = '';
  let limit = LIMIT;
  let offset = OFFSET;
  let requestData = REQUEST_DATA;

  ids.forEach((id) => {
    temp = temp + `{ field:"id=${id}" filterType:OR }`;
  });

  if (!isObjEmpty(optionalParams)) {
    const {
      LIMIT: OPTIONAL_LIMIT,
      OFFSET: OPTIONAL_OFFSET,
      REQUEST_DATA: OPTIONAL_REQUEST_DATA
    } = optionalParams;

    limit = OPTIONAL_LIMIT || LIMIT;
    offset = OPTIONAL_OFFSET || OFFSET;
    requestData = OPTIONAL_REQUEST_DATA || REQUEST_DATA;
  }

  return `query {
    searchFilter(
      filters:[${temp}]
      dataSet:BCG_ALL
      limit: ${limit}
      offset: ${offset}
    ){
    employees {${requestData}}
    }
    picture (ids: ["${ids.join('","')}"]){
      id,
      url,
      expirationSeconds
    }
  }
`;
};

const getDataByEmail = (email) => {
  return `query {
    employee(id: "${email}", field: "EMAIL_ADDRESS", dataSet:BCG_ALL) {
      id
      staffId
      title
      displayName
      jobFunction
      globalTitle
      email
      hostOfficeLocation {
        id
        name
        regionId
        street
        street2
        street3
        city
        state
        zipCode
        country
        countryCode
      }
    }
  }`;
};

const getSearchByNameQuery = (searchTerm, filter) => {
  return `query {
      searchFilter(
        compositeFilter: {
          logicOperator: AND
          subFilters: [
            {
              logicOperator: OR 
                  subFilters: [
                { valueFilter: { field: "firstName=${searchTerm}*" } }
                { valueFilter: { field: "lastName=${searchTerm}*" } }
                { valueFilter: { field: "name=${searchTerm}*" } }
              ]
            }
            ${filter}
            { valueFilter: { field: "alumni=NO" } }
          ]
        }
        dataSet:BCG_ALL limit: 50 offset:0 sortBy:["firstName", "lastName"]
      ){
      employees {
          id
          staffId
          title
          displayName
          jobFunction
          globalTitle
          email
          picture
          hostOfficeLocation {
            id
            name
            regionId
            street
            street2
            street3
            city
            state
            zipCode
            country
            countryCode
          }
      }
    }
  }
`;
};

export const actions = {
  // get people result

  getPeopleResult: (searchTerm, selectorName, filter) => async (dispatch) => {
    // Cancel API calls while typing only last API to perform the result
    cancelAxiosRequest();

    // dispatch pending state
    dispatch({ type: PEOPLE_DATA.API_PEOPLE_RESULTS_PENDING });

    try {
      /**
       * axiosConfig: request object to collect all http request related data
       * @param method: get, post, put etc
       * @param endpoint: api url
       * @param headers: custom headers
       * @param params: data to be sent in post calls
       */

      const axiosConfig = {
        method: 'post',
        endpoint: CONFIG.API_URL.GET_PEOPLE,
        headers: {
          'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
          psId: utils.getPsId(),
          'Content-Type': CONFIG.HTTP_CONTENT_TYPES.json,
        },
        params: { query: getSearchByNameQuery(searchTerm, filter) },
      };
      const data = await doAxiosRequest(axiosConfig);
      const peopleData = data?.data?.searchFilter?.employees;
      dispatch({
        type: PEOPLE_DATA.API_PEOPLE_RESULTS_SUCCESS,
        payload: { items: peopleData, searchTerm, selectorName },
      });
    } catch (error) {
      dispatch({
        type: PEOPLE_DATA.API_PEOPLE_RESULTS_FAILURE,
        payload: error.message,
      });
    }
  },
  getPeopleDataUsingIDs: (ids, isByEmail, optionalParams) => async (dispatch, getState) => {
    try {
      /**
       * axiosConfig: request object to collect all http request related data
       * @param method: get, post, put etc
       * @param endpoint: api url
       * @param headers: custom headers
       * @param params: data to be sent in post calls
       */
      const currentUserId = await getUserId();
      if (ids.length === 1 && ids[0] === currentUserId) {
        const currentUserData = globalSelectors.getUserDetails(getState());
        if (currentUserData && currentUserData?.id)
          return [currentUserData];
      }
      const axiosConfig = {
        method: 'post',
        endpoint: CONFIG.API_URL.GET_PEOPLE,
        headers: {
          'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
          psId: utils.getPsId(),
          'Content-Type': CONFIG.HTTP_CONTENT_TYPES.json,
        },
        params: {
          query: isByEmail ? getDataByEmail(ids[0]) : getDataByIDs(ids, optionalParams),
        },
      };
      const data = await doAxiosRequest(axiosConfig);
      const picture = data?.data?.picture;
      const employees = data?.data?.searchFilter?.employees;

      if (employees?.length && picture?.length) {
        employees.forEach((emp) => {
          const obj = picture.filter((d) => d.id === emp.id);
          emp["picture"] = obj[0]?.url;
        });
      }

      return isByEmail
        ? [data?.data?.employee]
        : employees;
    } catch (error) {
      console.error('error', error);
    }
  },

  // resetTypeAheadData: (selectorName) => {
  //   return {
  //     type: TYPEAHEAD_DATA.API_TYPEAHEAD_DATA_CLEAR,
  //     payload: { selectorName },
  //   }
  // },

  // getTypeAheadData: (selectorName, url) => async (dispatch) => {
  //   try {
  //     // dispatch pending state
  //     dispatch({ type: TYPEAHEAD_DATA.API_TYPEAHEAD_RESULTS_PENDING });
  //     const axiosConfig = {
  //       method: 'get',
  //       endpoint: url,
  //       headers: {
  //         'x-api-key': CONFIG.X_API_KEY
  //       }
  //     };
  //     const response = await doAxiosRequest(axiosConfig);
  //     dispatch({
  //       type: TYPEAHEAD_DATA.API_TYPEAHEAD_DATA_SUCCESS,
  //       payload: { items: response.value, selectorName },
  //     });
  //   } catch (error) {
  //     dispatch({
  //       type: TYPEAHEAD_DATA.API_TYPEAHEAD_RESULTS_FAILURE,
  //       payload: error.message,
  //     });
  //     throw error;
  //   }
  // },
};
