import React from "react";
import "./Example.scss";
import Image from "components/shared/Image";

export const Example = ({ text, subText }) => {
    return (
        <div className="example">
            <div className="exampleText">
                <p>{text}</p>
                <ul>
                    <li>
                        {subText}
                    </li>
                </ul>
            </div>
        </div>
    );
};
