/***
 * HOC to check whether the user have all the flags as false.
 * If yes, then redirect the user to error page.
 * Else, continue with displaying the passed on children
 * 
 */
import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";

import CONFIG from "config";
import CONSTANTS from "globalConstants";
import { ENTITLEMENTS } from "redux/constants";
import { checkAllEntitlementValue } from "utils/helpers/helpers";
import { ErrorPage } from "containers/ErrorPage/ErrorPage";
import ANALYTICS from "utils/analytics/analytics";
import { PAGES, PRIMARY_CATEGORY } from "utils/analytics/analytics_constants";

const {
  ENTITLEMENTS: {
    TRANSCRIPT_LIBRARY,
    SEARCH,
    CHAT
  },
  API_STATUS: {
    COMPLETED,
    ERROR,
    START,
    PENDING
  },
  ERROR_401,
  VIRTUAL_PAGE_VIEW,
  ERROR_ENTITLEMENT_API
} = CONSTANTS;

const {
  UI_URL: {
    CHAT_BOX,
    KEYWORD_SEARCH,
    ERROR: ERROR_401_URL,
    TRANCRIPT
  },
  OKTA: {
    REDIRECT_URL
  },
} = CONFIG;

export class Entitlement401RedirectContainer extends React.PureComponent {

  componentDidMount() {
    this.scrollToTop();
    const { getEntitlements } = this.props;
    getEntitlements();
  }

  componentDidUpdate(prevProps) {
    const {
      getEntitlements,
      history,
      entitlementApiStatus
    } = this.props;

    if (history.action !== "REPLACE" && history?.location?.pathname !== prevProps?.location?.pathname) {
      this.scrollToTop();
      if ([COMPLETED, ERROR].includes(entitlementApiStatus)) {
        getEntitlements();
      }
      if (!!window && window.usabilla_live) {
        window.usabilla_live(VIRTUAL_PAGE_VIEW);
      }
    }

    if (prevProps.entitlementApiStatus !== entitlementApiStatus) {
      if ([ERROR].includes(entitlementApiStatus)) {
        ANALYTICS.page.initialize({ pageName: PAGES.ENTITLEMENT_ERROR, category: PRIMARY_CATEGORY.ENTITLEMENT_ERROR_PAGE, trackPageLoad: true });
      }
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  shouldRedirectTo = () => {
    const {
      isSearchEnabled,
      isChatEnabled,
      match: {
        path
      }
    } = this.props;

    let redirectTo401 = false;

    if (isSearchEnabled === false && isChatEnabled === false) {
      redirectTo401 = true;
    } else if (isChatEnabled === false && path === CHAT_BOX) {
      redirectTo401 = true;
    }
    return redirectTo401;
  }

  redirectTo = () => <Redirect to={{ pathname: ERROR_401_URL(ERROR_401) }} />;

  render() {
    const {
      children,
      entitlementApiStatus
    } = this.props;

    const btnClick = () => {
      if (!!window) window.location.reload();
    }
    const btn2Click = () => {
      if (!!window && window.usabilla_live) window.usabilla_live("click");
    }

    return (
      <>
        {
          (![START, PENDING].includes(entitlementApiStatus)) ?
            ([ERROR].includes(entitlementApiStatus))
              ? <ErrorPage
                errorCodeProp={ERROR_ENTITLEMENT_API}
                btnClick={btnClick}
                btn2Click={btn2Click}
              /> :
              (this.shouldRedirectTo()
                ? this.redirectTo() : children
              )
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isSearchEnabled: entitlementSelectors.getEntitlementValue(state, TRANSCRIPT_LIBRARY, SEARCH),
  isChatEnabled: entitlementSelectors.getEntitlementValue(state, TRANSCRIPT_LIBRARY, CHAT),
  entitlementApiStatus: entitlementSelectors.getApiCallStatus(state)
});

const mapDispatchToProps = (dispatch) => ({
  getEntitlements: () => dispatch({ type: ENTITLEMENTS.GET_ENTITLEMENTS })
});

export const Entitlement401RedirectWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(Entitlement401RedirectContainer);

export default withRouter(Entitlement401RedirectWrapper);