import React, { useEffect, useRef, useState } from "react";
import "./FunctionTags.scss";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import CrossIconImage from "assets/images/crossiconv1.svg";
import SearchIcon from "assets/images/SearchIcon.svg";
import CrossIcon from "assets/images/cross-icon.svg";
import Arrow from "assets/images/Arrow.svg";
import Image from "components/shared/Image";
import Modal from "components/shared/Modal";
import { RESPONSE_DATA, ROLES, TAXONOMY } from "redux/constants";
import { Checkbox } from '@kdshared/enrich-ui';
import { useDispatch, useSelector } from "react-redux";
import { isInViewport, scrollToTop } from "utils/helpers/helpers";

// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";

const {
    SET_FILTERS,
    RETRIEVAL_API_RESULTS_SUCCESS,
    FUNCTION_FILTER_APPLIED,
    INDUSTRY_FILTER_APPLIED,
    CLEAR_TRANSCRIPT_SELECTED
} = RESPONSE_DATA;

const {
    RESET_ALL_SL_PARENT_TAGS
} = TAXONOMY;

export const FunctionTags = ({ showSourcesFilterContainer, handleFilterIconClick, companyList, lastUserQues }) => {

    const dispatch = useDispatch();

    const [searchCompany, setSearchCompany] = useState("");

    const filters = useSelector(responseSelector.getFilters);
    const latestSearchText = useSelector(responseSelector.getLatestSearchText);
    const queryResponse = useSelector(responseSelector.getRetrievalResult);
    const functionFilterApplied = useSelector(responseSelector.getFunctionFilterApplied);
    const industryFilterApplied = useSelector(responseSelector.getIndustryFilterApplied);
    const transcriptResults = queryResponse ? [...queryResponse] : [];
    const filterData = filters.filteredFunctionTags || [];
    const filterIndustryData = filters.filteredIndustryTags || [];
    const showRightPanel = filterData.filter(i => i.selected).length > 0;
    const sources = filters.sources || [];
    const groupedCompanies = filters.groupedCompanies || [];
    const actionButtonContainerElementRef = useRef(null);
    let filterRef = useRef();
    const pageURL = window.location.href;


    useEffect(() => {
        if (showSourcesFilterContainer && actionButtonContainerElementRef.current) {
            const actionButtonContainer = actionButtonContainerElementRef.current;
            let windowScroll = isInViewport(actionButtonContainer);
            let existingScroll = window.scrollY;
            scrollToTop(existingScroll + windowScroll);
        }
    }, [showSourcesFilterContainer]);

    useEffect(() => {
        if (searchCompany.length > 1) {
            const newFilterData = [...filterData];
            let isSelected = false;
            newFilterData.map((filter) => {
                let ifMatches = filter.tagName.toUpperCase().indexOf(searchCompany.toUpperCase()) !== -1;
                filter.show = ifMatches;
                if (!isSelected && ifMatches) {
                    filter.selected = true;
                    isSelected = true;
                } else
                    filter.selected = false;
                if (filter.children && filter.children.length > 0) {
                    const ifMatches1 = filter.children.filter(filterLevel1 => filterLevel1.tagName.toUpperCase().indexOf(searchCompany.toUpperCase()) !== -1).length > 0;
                    if (ifMatches === false) filter.show = ifMatches1;
                    if (!isSelected && ifMatches1) {
                        filter.selected = true;
                        isSelected = true;
                    }
                    let showFilter = false;
                    filter.children.forEach(filterLevel1 => {
                        if (ifMatches === false || ifMatches === true) {
                            if (filterLevel1.children && filterLevel1.children.length > 0) {
                                const ifMatches2 = filterLevel1.children.filter(filterLevel2 => filterLevel2.tagName.toUpperCase().indexOf(searchCompany.toUpperCase()) !== -1).length > 0;
                                if (showFilter === false) showFilter = ifMatches2;
                                filterLevel1.selected = ifMatches2;
                                if (!isSelected && ifMatches2) {
                                    filter.selected = true;
                                    isSelected = true;
                                }
                            }
                        }
                    })
                    if (ifMatches1 === false) filter.show = showFilter;
                }
            })
            dispatch({ type: SET_FILTERS, payload: { filteredFunctionTags: newFilterData } });
        } else {
            const newFilterData = [...filterData];
            newFilterData.map((filter) => {
                filter.show = true;
                if (!filter.filterShow || (functionFilterApplied > 0 ? !filter.visible : false)) {
                    filter.disabled = true;
                } else {
                    filter.disabled = false;
                    filter.checked = functionFilterApplied > 0;
                }
                filter.children && filter.children.length > 0 && filter.children.map(filterLevel1 => {
                    filterLevel1.selected = false;
                    if (!filterLevel1.filterShow || (functionFilterApplied > 0 ? !filterLevel1.visible : false)) {
                        filterLevel1.disabled = true;
                    } else {
                        filterLevel1.disabled = false;
                        filterLevel1.checked = functionFilterApplied > 0;
                    }
                    let selectFilter = false;
                    filterLevel1.children && filterLevel1.children.length > 0 && filterLevel1.children.map(filterLevel2 => {
                        if (!filterLevel2.filterShow || (functionFilterApplied > 0 ? !filterLevel2.visible : false)) {
                            filterLevel2.disabled = true;
                        } else {
                            filterLevel2.disabled = false;
                            filterLevel2.checked = functionFilterApplied > 0;
                            //if (selectFilter === false) selectFilter = functionFilterApplied > 0;
                            selectFilter = true;
                        }
                    })
                    if (filterLevel1.disabled) filterLevel1.selected = selectFilter;
                })
            })

            dispatch({ type: SET_FILTERS, payload: { filteredFunctionTags: newFilterData } });
        }
    }, [searchCompany]);

    const handleClearClick = (e) => {
        const newFilterData = [...filterData];
        newFilterData.map((filter) => {
            filter.checked = false;
            filter.children && filter.children.length > 0 && filter.children.map(filterLevel1 => {
                filterLevel1.checked = false;
                filterLevel1.children && filterLevel1.children.length > 0 && filterLevel1.children.map(filterLevel2 => {
                    filterLevel2.checked = false;
                })

            })
        })
        dispatch({ type: SET_FILTERS, payload: { filteredFunctionTags: newFilterData } });
        handleApplyClick();
    }

    const handleCrossClick = (e) => {
        handleFilterIconClick(false);
    }

    const handleTagClick = (filter) => {
        const FD = [...filterData];
        FD.forEach((filter1) => filter1.selected = filter1.tagName === filter.tagName);
        dispatch({ type: SET_FILTERS, payload: { filteredFunctionTags: FD } });
    }

    const handleTagClick2 = (filter1, filter2) => {
        const newFilterData = [...filterData];
        newFilterData.map((filter) => {
            if (filter.tagName === filter1) {
                filter.children.map(filterLevel1 => {
                    if (filterLevel1.tagName === filter2) {
                        filterLevel1.selected = !filterLevel1.selected;
                    }
                })

            }
        })
        dispatch({ type: SET_FILTERS, payload: { filteredIndustryTags: newFilterData } });
    }
    const handleApplyClick = (e) => {

        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Transcript_Library_Chatbot,
                    previousPageName: PAGES.Transcript_Library_Chatbot,
                    pageURL: pageURL
                }
            },
            internal: {
                search: { term: lastUserQues }
            }
        };
        newAdobeData.filterCategory = "FunctionPA";





        handleFilterIconClick(false);
        //dispatch({ type: QUERY_RESPONSE, payload: { question: latestSearchText, filters, dispatch } });
        let newFilterData = [...filterData];

        let selectedTranscripts = [];
        let tagsSelected = [];
        newFilterData.forEach((filter) => {
            if (filter.checked) {
                selectedTranscripts = [...selectedTranscripts, ...filter.transcriptIds];
                tagsSelected.push(filter.tagName);
            }
            filter.children && filter.children.length > 0 && filter.children.forEach((filter1) => {
                if (filter1.checked) {
                    selectedTranscripts = [...selectedTranscripts, ...filter1.transcriptIds];
                    tagsSelected.push(filter1.tagName);
                }
                filter1.children && filter1.children.length > 0 && filter1.children.forEach((filter2) => {
                    if (filter2.checked) {
                        selectedTranscripts = [...selectedTranscripts, ...filter2.transcriptIds];
                        tagsSelected.push(filter2.tagName);
                    }
                })
            })
        })
        newAdobeData.filterChecked = tagsSelected.join();
        ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.FILTER_CLICKS);
        transcriptResults.forEach((transcript) => {
            const meeting_id = transcript.meeting_id;
            if (selectedTranscripts.length > 0 && selectedTranscripts.indexOf(meeting_id) === -1) {
                transcript.isHideFF = true;
            } else {
                transcript.isHideFF = false;
            }
        })

        selectedTranscripts = transcriptResults.filter(tr => !tr.isHide && !tr.isHideCF && !tr.isHideSF && !tr.isHideFF && !tr.isHideIF);

        const enabledMonths = [...new Set(selectedTranscripts.map(i => new Date(i.date_of_interview * 1000).getMonth() + "" + new Date(i.date_of_interview * 1000).getFullYear()))];
        //const initialYear = enabledMonths.map(i => i.substring(i.length === 5 ? 1 : 2)).sort()[0];
        dispatch({
            type: SET_FILTERS, payload: {
                response: {
                    enabledMonths
                }
            }
        });

        const dummySources = {};
        selectedTranscripts.forEach((transcript) => {
            if (dummySources[transcript.vendor_source]) {
                dummySources[transcript.vendor_source].push(transcript.meeting_id);
            } else {
                dummySources[transcript.vendor_source] = [transcript.meeting_id];
            }
        })
        const newSourceList = [...sources];
        newSourceList.map(status => {
            status.value = status.key + " (" + ((dummySources[status.key] && dummySources[status.key].length) || 0) + ")";
            status.transcriptIds = dummySources[status.key] || [];
            status.show = dummySources[status.key] ? dummySources[status.key].length > 0 : false;
        })


        const availableCompanies = []
        selectedTranscripts.forEach((transcript) => {
            const expert_employment_history = transcript.expert_employment_history;
            expert_employment_history && expert_employment_history.length > 0 &&
                expert_employment_history.forEach(eeh => availableCompanies.push(eeh.company));
        })

        const companyList = [];

        const newGroupedCompanies = [...groupedCompanies];
        newGroupedCompanies.map(group => {
            group.children.map(status => {
                const ifMatches = availableCompanies.indexOf(status.key.substring(0, status.key.lastIndexOf(" ("))) > -1;
                status.filterShow = ifMatches;
                if (status.selected && ifMatches) {
                    companyList.push(status.key.substring(0, status.key.lastIndexOf(" (")));
                }
            })
            group.filterShow = group.children.filter(status => status.filterShow).length > 0;
        })
        dispatch({
            type: SET_FILTERS, payload: {
                newGroupedCompanies
            }
        });

        dispatch({ type: SET_FILTERS, payload: { sources: newSourceList } });

        const selectedMeetingIds = selectedTranscripts.map(tr => tr.meeting_id);
        newFilterData.map((filter) => {
            filter.visible = tagsSelected.length > 0 ? filter.checked : true;
            filter.children && filter.children.length > 0 && filter.children.map(filterLevel1 => {
                filterLevel1.visible = tagsSelected.length > 0 ? filterLevel1.checked : true;
                filterLevel1.children && filterLevel1.children.length > 0 && filterLevel1.children.map(filterLevel2 => {
                    filterLevel2.visible = tagsSelected.length > 0 ? filterLevel2.checked : true;
                })
            })
        })
        if (tagsSelected.length === 0) {
            newFilterData.map((filter) => {
                if (selectedMeetingIds.filter(id => filter.transcriptIds.includes(id)).length > 0) {
                    filter.filterShow = true;
                } else {
                    filter.filterShow = false;
                    filter.checked = false;
                }
                filter.children && filter.children.length > 0 && filter.children.map(filterLevel1 => {
                    if (selectedMeetingIds.filter(id => filterLevel1.transcriptIds.includes(id)).length > 0) {
                        filterLevel1.filterShow = true;
                    } else {
                        filterLevel1.filterShow = false;
                        filterLevel1.checked = false;
                    }
                    filterLevel1.children && filterLevel1.children.length > 0 && filterLevel1.children.map(filterLevel2 => {
                        if (selectedMeetingIds.filter(id => filterLevel2.transcriptIds.includes(id)).length > 0) {
                            filterLevel2.filterShow = true;
                        } else {
                            filterLevel2.filterShow = false;
                            filterLevel2.checked = false;
                        }
                    })
                })
            })
        }
        dispatch({ type: SET_FILTERS, payload: { filteredFunctionTags: newFilterData } });
        dispatch({ type: FUNCTION_FILTER_APPLIED, payload: { response: tagsSelected.length } });

        const allTranscriptSelected = selectedTranscripts.length === transcriptResults.length;
        newFilterData = [...filterIndustryData];
        tagsSelected = [];
        newFilterData.map((filter) => {
            if (selectedMeetingIds.filter(id => filter.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                filter.filterShow = true;
                if (filter.visible === true) {
                    tagsSelected.push(filter.tagName);
                }
            } else {
                filter.filterShow = false;
                filter.checked = false;
            }
            filter.children && filter.children.length > 0 && filter.children.map(filterLevel1 => {
                if (selectedMeetingIds.filter(id => filterLevel1.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                    filterLevel1.filterShow = true;
                    if (filterLevel1.visible === true) {
                        tagsSelected.push(filterLevel1.tagName);
                    }
                } else {
                    filterLevel1.filterShow = false;
                    filterLevel1.checked = false;
                }
                filterLevel1.children && filterLevel1.children.length > 0 && filterLevel1.children.map(filterLevel2 => {
                    if (selectedMeetingIds.filter(id => filterLevel2.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                        filterLevel2.filterShow = true;
                        if (filterLevel2.visible === true) {
                            tagsSelected.push(filterLevel2.tagName);
                        }
                    } else {
                        filterLevel2.filterShow = false;
                        filterLevel2.checked = false;
                    }
                })
            })
        })
        dispatch({ type: SET_FILTERS, payload: { filteredIndustryTags: newFilterData } });
        industryFilterApplied > 0 && dispatch({ type: INDUSTRY_FILTER_APPLIED, payload: { response: tagsSelected.length } });

        dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });
        dispatch({ type: RETRIEVAL_API_RESULTS_SUCCESS, payload: { response: transcriptResults } });
        dispatch({ type: RESET_ALL_SL_PARENT_TAGS, payload: { response: "" } });
    }

    const getHighlightedText = (text, highlight) => {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span>
            {
                parts.map((part, i) =>
                    <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { color: '#3EAD93' } : {}}>
                        {part}
                    </span>)
            }
        </span>;
    }

    const handleStatusClick = (event, data, level, filter1, filter2, filter3) => {
        event.stopPropagation();
        const checkboxChecked = data.checked;
        const newFilterData = [...filterData];
        if (level === 3) {
            newFilterData.map((filter) => {
                if (filter.tagName === filter1) {
                    filter.children.map(filterLevel1 => {
                        if (filterLevel1.tagName === filter2) {
                            filterLevel1.children.map(filterLevel2 => {
                                if (filterLevel2.tagName === filter3) {
                                    filterLevel2.checked = checkboxChecked;
                                }
                            })
                            if (!filterLevel1.disabled) {
                                filterLevel1.children
                                    .filter(filterLevel2 => filterLevel2.checked === false && filterLevel2.disabled === false)
                                    .length === 0 ? (filterLevel1.checked = true) : (filterLevel1.checked = false);
                            }
                        }
                    })
                    if (!filter.disabled) {
                        filter.children
                            .filter(filterLevel1 => filterLevel1.checked === false && filterLevel1.disabled === false)
                            .length === 0 ? (filter.checked = true) : (filter.checked = false);
                    }
                }
            })
        }
        if (level === 2) {
            newFilterData.map((filter) => {
                if (filter.tagName === filter1) {
                    filter.children.map(filterLevel1 => {
                        if (filterLevel1.tagName === filter2) {
                            filterLevel1.checked = checkboxChecked;
                            filterLevel1.children && filterLevel1.children.length > 0
                                && filterLevel1.children.filter(filterLevel2 => !filterLevel2.disabled).map(filterLevel2 => {
                                    filterLevel2.checked = checkboxChecked;
                                })
                        }
                    })
                    filter.children
                        .filter(filterLevel1 => filterLevel1.checked === false && filterLevel1.disabled === false)
                        .length === 0 ? (filter.checked = true) : (filter.checked = false);
                }
            })
        }
        if (level === 1) {
            newFilterData.map((filter) => {
                if (filter.tagName === filter1) {
                    filter.checked = checkboxChecked;
                    filter.children && filter.children.length > 0 &&
                        filter.children.filter(filterLevel1 => !filterLevel1.disabled).map(filterLevel1 => {
                            filterLevel1.checked = checkboxChecked;
                            filterLevel1.children && filterLevel1.children.length > 0
                                && filterLevel1.children.filter(filterLevel2 => !filterLevel2.disabled).map(filterLevel2 => {
                                    filterLevel2.checked = checkboxChecked;
                                })

                        })
                }
            })
        }

        dispatch({ type: SET_FILTERS, payload: { filteredFunctionTags: newFilterData } });
    };

    return (
        <div className="taxonomy-filter-container">
            <Modal
                isOpen={true}
                className="taxonomy-filter-modal">

                <div className="header">
                    <div className="modal-header-text">Function PA</div>
                    <Image className="cross-image" src={CrossIconImage} alt="CrossIconImage" onClick={handleCrossClick} />
                </div>
                <div className="body">
                    <div className="filter-search-box">
                        <Image src={SearchIcon} alt="logo" />
                        <input value={searchCompany} onChange={(e) => setSearchCompany(e.target.value)} placeholder="Type keyword to filter Function PA" />
                        <Image className="cross-icon" src={CrossIcon} alt="CrossIcon" onClick={() => setSearchCompany('')} />
                    </div>
                    <div className="filter-main-content">
                        <div className={`left-panel ${filterData.filter(filter => filter.show !== false).length === 0 && 'remove-border'}`}>
                            {filterData.map((filter, index1) => (filter.show !== false &&
                                <div onClick={() => handleTagClick(filter)} className={`filter-item-container ${filter.selected && "selected"}`}>
                                    <div className="filter-item">
                                        <Checkbox disabled={filter.disabled} className="filter-checkbox" value={filter.tagName} checked={filter.checked} onClick={(event, data) => handleStatusClick(event, data, 1, filter.tagName)} />
                                        <span className={`checkbox-label ${filter.disabled ? 'disabled' : ''}`}>{getHighlightedText(filter.tagName, searchCompany)}</span>
                                        <Image className="arrow-icon" src={Arrow} alt="Arrow" />
                                    </div>
                                </div>
                            ))}
                        </div>
                        {showRightPanel && <div className="right-panel">
                            {filterData.filter((filter) => filter.selected)[0].children.filter((filter) => filter.show !== false).map((filter, index1) => {
                                return (
                                    <>
                                        <div className="filter-item-container" onClick={() => handleTagClick2(filterData.filter((filter) => filter.selected)[0].tagName, filter.tagName)}>
                                            <div className="filter-item">
                                                <Checkbox disabled={filter.disabled} className="filter-checkbox" value={filter.tagName} checked={filter.checked} onClick={(event, data) => handleStatusClick(event, data, 2, filterData.filter((filter) => filter.selected)[0].tagName, filter.tagName)} />
                                                <span className={`checkbox-label ${filter.disabled ? 'disabled' : ''}`}>{getHighlightedText(filter.tagName, searchCompany)}</span>
                                                {filter.children && filter.children.length > 0 && <Image className={`arrow-icon ${filter.selected && 'selected'}`} src={Arrow} alt="Arrow" />}
                                            </div>
                                        </div>
                                        {
                                            filter.selected && filter.children && filter.children.filter((filter) => true).map((filter1, index1) => (
                                                <div className="filter-item-container grand-child">
                                                    <div className="filter-item">
                                                        <div className="arrow-div"></div>
                                                        <Checkbox disabled={filter1.disabled} className="filter-checkbox" value={filter1.tagName} checked={filter1.checked} onClick={(event, data) => handleStatusClick(event, data, 3, filterData.filter((filter) => filter.selected)[0].tagName, filter.tagName, filter1.tagName)} />
                                                        <span className={`checkbox-label ${filter1.disabled ? 'disabled' : ''}`}>{getHighlightedText(filter1.tagName, searchCompany)}</span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                                )
                            })}
                        </div>
                        }
                    </div>
                </div>
                <div className="footer">
                    <div className="filter-action-item" >
                        <button className="btn secondary button" onClick={handleClearClick}>Clear</button>
                        <button className="btn primary button" onClick={handleApplyClick}>Apply Now</button>
                    </div>
                </div>
            </Modal>
        </div >

    )
};
