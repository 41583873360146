import React from "react";
import PropTypes from "prop-types";
import { Pill } from "components/shared/Pill";
import { Tooltip } from "components/shared/Tooltip";
import LABELS from "labels";

import "./Custodian.scss";

const Custodian = ({
  data,
  clickHandler,
  tooltipHtml,
  className,
  polyToolTipMsg
}) => {
  const {
    METADATA: {
      SECTOR_OWNER_TITLE
    }
  } = LABELS;
  return (
    <>
      {!!data.length && (
        <div className="pa-custodian-wrap">
          <label>{SECTOR_OWNER_TITLE}</label>
          {tooltipHtml && (
            <Tooltip id="taxonomy_tooltip_custodian">{tooltipHtml}</Tooltip>
          )}
          <div className={className}>
            <div className="custodian">
              {
                data.map(
                  pilldata => <Pill
                    key={pilldata?.id}
                    defaultText={pilldata?.label}
                    id={pilldata?.id}
                    active={pilldata?.isOwner}
                    onClickHandler={() => clickHandler(pilldata?.id)}
                    toolTipMsg={polyToolTipMsg}
                    showToolTip={pilldata?.isPolyHierarchi}
                    isDisable={pilldata?.isPolyHierarchi}
                    showPolyIcon={pilldata?.isPolyHierarchi}
                  />)
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Custodian.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isOwner: PropTypes.bool,
  })).isRequired,
  clickHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Custodian.defaultProps = {
  className: "",
};

export default Custodian;
