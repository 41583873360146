// import TaxonomySaga from "redux/actions/taxonomy/Taxonomy.saga.js";
import { put, all, call } from 'redux-saga/effects';
import { globalSagaFailure } from "./global.action";
import ResponseSaga from "redux/actions/response/response.saga";
import docvizSaga from 'redux/actions/docviz/docviz.saga.js';
import authorizationSaga from "redux/actions/authorization/authorization.saga.js";
import TaxonomySaga from "redux/actions/taxonomy/Taxonomy.saga.js";

export default function* globalSaga() {
    try {
        yield all([
            call(ResponseSaga),
            call(docvizSaga),
            call(authorizationSaga),
            call(TaxonomySaga)
        ]);
    } catch (err) {
        yield put(globalSagaFailure(err))
    }
}