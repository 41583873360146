import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getSelectedTranscript, getDocvizRequestIds, getDownloadCount, getTranscriptDetail } from "redux/actions/response/response.actions";

import { Layout } from "containers/Layout";
import LABELS from "labels";
import { useHistory } from "react-router-dom";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";
import CONSTANTS from "globalConstants";
import { TranscriptDetailed } from "../../components/TranscriptDetailed";
import { TAXONOMY } from "redux/constants";
// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";
import { sendToLocalStorage, getFromLocalStorageAsJSON, removeFromLocalStorage } from 'utils/localStorage/localStorage';
import "./SingleTranscript.scss";

const {
    ENTITLEMENTS: {
        TRANSCRIPT_LIBRARY,
        DOWNLOAD
    },
    API_STATUS: {
        COMPLETED,
        ERROR,
        START,
        PENDING
    }
} = CONSTANTS;
const SingleTranscriptContainer = ({
    selectedTranscript,
    getSelectedTranscript,
    getDocvizRequestIds,
    getDownloadCount,
    getTranscriptDetail,
    resetAllParentTags,
    match,
    isDownloadEnabled,
    entitlementApiStatus,
    match: {
        params: { transcriptId },
    }
}) => {
    const history = useHistory();
    const pageURL = window.location.href;
    const eVar2 = getFromLocalStorageAsJSON("eVar2ForDetailedTranscript");
    removeFromLocalStorage("eVar2ForDetailedTranscript");
    useEffect(() => {
        if (selectedTranscript === '' && (![START, PENDING].includes(entitlementApiStatus))) {
            getSelectedTranscript(transcriptId);
            getDocvizRequestIds([transcriptId]);
            getDownloadCount(transcriptId);
            getTranscriptDetail(transcriptId);
        }
    }, [selectedTranscript, entitlementApiStatus]);

    useEffect(() => {
        (![START, PENDING].includes(entitlementApiStatus)) && ANALYTICS.page.initialize({
            pageName: PAGES.Detailed_Transcript_View,
            previousPageName: eVar2 === "fromChatBot" ? PAGES.Transcript_Library_Chatbot : eVar2 === "fromKeywordSearch" ? PAGES.Transcript_Library_Keyword_Search : PAGES.Detailed_Transcript_View,
            category: PRIMARY_CATEGORY.Transcript_Library,
            trackPageLoad: true
        });
    }, [entitlementApiStatus]);

    const clearChat = () => {
        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Detailed_Transcript_View,
                    previousPageName: PAGES.Detailed_Transcript_View,
                    pageURL: window.location.origin + "/transcript/" + transcriptId,
                }
            },
            linkName: "Home",
            link: { toolSearch: "NA" },
            internal: {
                search: { term: "NA" }
            }

        };
        ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.BUTTON_LINK_CLICKS);
        resetAllParentTags();
        sendToLocalStorage("eVar2ForPreviousPage", "fromSingleTranscript");
        history.push("/");
    };

    return (
        <Layout id="chat" layoutClass="layout__chat-section" clearChat={clearChat} activeRoute="SingleTranscript" match={match} location={location} history={history}>
            <div className={"container__selectedTranscript"}>
                {selectedTranscript !== '' && <TranscriptDetailed transcript={selectedTranscript} isDownloadEnabled={isDownloadEnabled}></TranscriptDetailed>}
            </div>
        </Layout >
    );
};

const mapStateToProps = (state) => ({
    selectedTranscript: responseSelector.getSelectedTranscript(state),
    isDownloadEnabled: entitlementSelectors.getEntitlementValue(state, TRANSCRIPT_LIBRARY, DOWNLOAD),
    entitlementApiStatus: entitlementSelectors.getApiCallStatus(state)
});

const mapDispatchToProps = (dispatch) => ({
    getSelectedTranscript: (transcriptId) => dispatch(getSelectedTranscript(transcriptId)),
    getDocvizRequestIds: (transcriptId) => dispatch(getDocvizRequestIds(transcriptId)),
    getDownloadCount: (transcriptId) => dispatch(getDownloadCount(transcriptId)),
    getTranscriptDetail: (transcriptId) => dispatch(getTranscriptDetail(transcriptId)),
    getSLparentTags: (key, ids) => dispatch({ type: TAXONOMY.GET_SL_PARENT_TAGS_DATA, payload: { ids, key } }),
    resetAllParentTags: () => dispatch({ type: TAXONOMY.RESET_ALL_SL_PARENT_TAGS, payload: { response: "" } }),
});


export const SingleTranscript = connect(
    mapStateToProps,
    mapDispatchToProps
)(SingleTranscriptContainer);

export { SingleTranscriptContainer };

