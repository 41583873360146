import React from "react";
import "./UserChatMessage.scss";
import Image from "components/shared/Image";
import userIcon from "assets/images/user_icon.svg";

export const UserChatMessage = ({ message }) => {
    return (
        <div className="container__userChatMessage">
            <div className="message">{message}</div>
            <div className="mainheader__userProfile"><Image src={userIcon} alt="logo" /></div>
        </div>
    );
};
