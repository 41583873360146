import React from "react";

import "./LoadingDotsFlashing.scss";

export const LoadingDotsFlashing = () => {

    return (
        <div className="loading-dot-flashing__wrap">
            <div className="loading-dot-flashing__item">
                <span className="loading-dot-flashing__item__first"></span>
                <span className="loading-dot-flashing__item__second"></span>
                <span className="loading-dot-flashing__item__third"></span>
            </div>
            
            <span className="loading-dot-flashing__info">Generating answers for you...</span>
        </div>
    );
};
