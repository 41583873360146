import { DOCVIZ } from "redux/constants";
import { mapMetadataToDoc } from 'utils/docviz/docviz';

export const initialState = {
  loading: false,
  errorState: false,
  errorDetails: "",
  metadata: "",
  doc: "",
  clippingId: "",
  downloadStatus: "",
  downloadLink: "",
  multiDocvizMetaData: [],
  multiDocvizInitialMetaData: [],
  multiDocvizFinalMetaData: [],
  docvizInitialLoading: false,
  docvizFinalLoading: false,
  docvizShowMoreStatus: true
};
// local variable
export const name = "docvizmetadata";

// reducer selectors
export const selectors = {
  getLoading: state => state[name].loading,
  getErrorState: state => state[name].errorState,
  getErrorDetails: state => state[name].errorDetails,
  getDocvizMetadata: (state) => state[name].metadata,
  getDocvizMultiMetaData: (state) => state[name].multiDocvizMetaData,
  getDocvizDocument: (state) => state[name].doc,
  getDownloadStatus: (state) => state[name].downloadStatus,
  getDownloadLink: (state) => state[name].downloadLink,
  getDocvizInitialLoading: (state) => state[name].docvizInitialLoading,
  getDocvizFinalLoading: (state) => state[name].docvizFinalLoading,
  getDocvizShowMoreStatus: (state) => state[name].docvizShowMoreStatus,
  getDocvizMultiMetaAllData: (state) => state[name].multiDocvizFinalMetaData
};

const updateDocvizMetaMulti = (state, payload) => {
  const { multiDocvizMetaData } = state;
  const { data: idsData, ids, isShowMoreClicked } = payload;
  let finalData = idsData.map((elementData, index) => {
    const mergedData = mapMetadataToDoc(ids[index], elementData);
    return mergedData;
  });
  let docvizShowMoreStatus = true;
  let initialDocvizData = [];
  let finalDocvizData = [];

  if (isShowMoreClicked) {
    initialDocvizData = multiDocvizMetaData;
    finalData = [...initialDocvizData, ...finalData];
    finalDocvizData = finalData;
    docvizShowMoreStatus = false;
  }

  return {
    ...state,
    loading: false,
    errorState: false,
    errorDetails: "",
    docvizInitialLoading: false,
    docvizFinalLoading: false,
    multiDocvizMetaData: finalData,
    docvizShowMoreStatus,
    multiDocvizInitialMetaData: initialDocvizData,
    multiDocvizFinalMetaData: finalDocvizData
  }
}

const resetDocvizMetaMulti = (state, payload) => {
  const { multiDocvizFinalMetaData, multiDocvizInitialMetaData } = state;
  const { isShowMoreClicked } = payload;

  return {
    ...state,
    docvizShowMoreStatus: isShowMoreClicked ? false : true,
    multiDocvizMetaData: isShowMoreClicked ? multiDocvizFinalMetaData : multiDocvizInitialMetaData
  }
}

// reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case DOCVIZ.GET_DOCVIZ_METADATA_FAILURE:
      return {
        ...state,
        loading: false,
        errorState: true,
        errorDetails: action.payload.data
      };

    case DOCVIZ.GET_DOCVIZ_METADATA_MULTI_FAILURE:
      return {
        ...state,
        loading: false,
        docvizInitialLoading: false,
        docvizFinalLoading: false,
        errorState: true,
        errorDetails: action.payload.data
      };

    case DOCVIZ.GET_DOCVIZ_METADATA_MULTI_PENDING:
      const { docvizInitialLoading, docvizFinalLoading } = state;
      const { payload: { isShowMoreClicked } } = action;
      return {
        ...state,
        docvizInitialLoading: isShowMoreClicked ? docvizInitialLoading : true,
        docvizFinalLoading: isShowMoreClicked ? true : docvizFinalLoading,
        errorState: false,
        errorDetails: ""
      };

    case DOCVIZ.GET_DOCVIZ_METADATA_PENDING:
      return {
        ...state,
        loading: true,
        errorState: false,
        errorDetails: "",
        metadata: "",
        doc: { ...state.doc }
      };
    case DOCVIZ.RESET_DOCVIZ_METADATA:
      return {
        ...state,
        loading: false,
        errorState: false,
        errorDetails: "",
        metadata: "",
        doc: ""
      };
    case DOCVIZ.GET_DOCVIZ_METADATA_SUCCESS:
      return {
        ...state,
        loading: false,
        errorState: false,
        errorDetails: "",
        metadata: action.payload.data,
        doc: { ...state.doc, ...mapMetadataToDoc(action.payload.id, action.payload.data, action.payload.meeting_id) }
      };
    case DOCVIZ.UPDATE_DOCVIZ_METADATA_DOC:
      return {
        ...state,
        doc: { ...state.doc, ...action.payload.response }
      };
    case DOCVIZ.GET_DOCVIZ_METADATA_MULTI_SUCCESS:
      return updateDocvizMetaMulti(state, action.payload);
    case DOCVIZ.UPDATE_ONLY_FRAMEWORK_SLIDES:
      return {
        ...state,
        metadata: { ...state?.metadata, slidesTagged: action.payload.slidesTagged },
        doc: { ...state?.doc, frameworkSlides: action.payload.slidesTagged }
      }
    case DOCVIZ.DOWNLOAD_CLIPPED_SLIDES_FAILED:
    case DOCVIZ.FETCH_CLIPPED_ID_FAILED:
    case DOCVIZ.DOWNLOAD_CLIPPED_SLIDES_STARTED:
    case DOCVIZ.CLIPPED_SLIDES_FAILURE:
      return {
        ...state,
        downloadStatus: action.payload.downloadStatus
      };
    case DOCVIZ.GENERATING_CLIPPED_SLIDES:
      return {
        ...state,
        downloadStatus: action.payload.downloadStatus,
        clippingId: action.payload.clippingId
      };
    case DOCVIZ.DOWNLOAD_CLIPPED_SLIDES_COMPLETE:
    case DOCVIZ.DOWNLOAD_CLIPPED_SLIDES_INPROGRESS:
    case DOCVIZ.CLIPPED_FILE_STATUS_ERROR:
      return {
        ...state,
        downloadStatus: action.downloadStatus,
        clippingId: action.payload.clippingId,
        downloadLink: action.payload.downloadLink
      };

    case DOCVIZ.UPDATE_DOCVIZ_METADATA_MULTI:
      return resetDocvizMetaMulti(state, action.payload);

    default:
      return state;
  }
}
