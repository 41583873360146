import React, { useEffect, useState } from "react";
import Image from "components/shared/Image";
import { Button } from "components/shared/Button";
import logoIcon from "assets/images/tl_logo_white.svg";
import userIcon from "assets/images/Profile_White.svg";
import BookmarkIconHeader from "assets/images/bookmark_icon_header.svg";
import HistoryIcon from "assets/images/keyword_history_icon.svg";
import complianceButtonIcon from "assets/images/compliance_button_icon.svg";
import { Tooltip } from "components/shared/Tooltip";
import { downloadFileFromUrl } from "utils/helpers/helpers";

import "./Header.scss";
import { Overlay } from "components/Overlay/Overlay";



export const Header = ({ clearChat }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayType, setOverlayType] = useState("bookmark");
  const openComplianceInNewTab = (event) => {
    event.stopPropagation();
    downloadFileFromUrl(`https://bcgcloudasp.sharepoint.com/:p:/r/sites/Case_Team_Services/_layouts/15/Doc.aspx?sourcedoc=%7BDC5A795E-A3B6-4DC6-A089-A4D670DAFED1%7D&file=Compliance%20Overview%20Slide.pptx&action=edit&mobileredirect=true`, "_blank");
  };
  const handleBookmarkClick = () => {
    setOverlayType("bookmark");
    setShowOverlay(!showOverlay);
  }
  const handleHistoryClick = () => {
    setOverlayType("history");
    setShowOverlay(!showOverlay);
  }
  return (
    <>
      {showOverlay && <Overlay type={overlayType} setShowOverlay={setShowOverlay} />}
      <div className="mainheader__wrap">
        <div className="mainheader__logo">
          <Image onClick={() => clearChat()} src={logoIcon} alt="logo" />
          <div className="mainheader__logo-text">
            <p>External Experts Transcript Library</p>
            <h1>AI-powered transcript assistant</h1>
          </div>
        </div>
        <div className="mainheader__userProfile">
          <Image src={userIcon} alt="logo" />
        </div>
        <div className="mainheader__compliance">
          <Image data-tip data-for='CompliaceIcon' className="compliance-icon" src={complianceButtonIcon} alt="complianceButtonIcon"
            onClick={(e) => openComplianceInNewTab(e)} />
          <Tooltip position="bottom" id="CompliaceIcon" delayHide={0}>Compliance</Tooltip>
        </div>
        <div className="mainheader__history">
          <Image data-tip data-for='HistoryIcon' className="history-icon" src={HistoryIcon} onClick={() => handleHistoryClick()} alt="HistoryIcon" />
          <Tooltip position="bottom" id="HistoryIcon" delayHide={0}>History</Tooltip>
        </div>
        <div className="mainheader__bookmark">
          <Image data-tip data-for='BookmarkIcon' className="bookmark-icon" src={BookmarkIconHeader} onClick={() => handleBookmarkClick()} alt="BookmarkIconHeader" />
          <Tooltip position="bottom" id="BookmarkIcon" delayHide={0}>Bookmarks</Tooltip>
        </div>
      </div >
    </>
  );
}



