import React from "react";
import Image from "components/shared/Image";
import CrossIconImage from "assets/images/crossiconv1.svg";
import "./Overlay.scss";
import { Bookmarks } from "components/Bookmarks";
import { History } from "components/History";


export const Overlay = ({ type, setShowOverlay }) => {
  return (
    <div className="overlay" onClick={() => setShowOverlay(false)}>
      <div className="overlay__parent" onClick={(e) => e.stopPropagation()}>
        <div className="overlay__header">
          <div className="overlay__heading">
            {type === "bookmark" ? "Saved Bookmarks" : "History"}
          </div>
          <div className="overlay__closeIcon">
            <Image className="cross-image" src={CrossIconImage} alt="CrossIconImage" onClick={() => setShowOverlay(false)} />
          </div>
        </div>
        <div className="overlay__body">
          {type === "bookmark" && <Bookmarks />}
          {type === "history" && <History />}
        </div>
      </div >
    </div >
  );
}



