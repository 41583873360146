export function mapMetadataToDoc(kpId, metadata, meeting_id) {
  const doc = {
    entitled: true, // whether or not the user is entitled to view this file
    kpId: meeting_id, // the ID of the document
    title: "",
    baseURL: metadata.Url + "" + metadata.S3Object + "/",
    frameworkSlides: [], // By default the value is empty array, this will contain array of objects of slide number which are tagged as framework slides. Example : [{"id":"1"},{"id":"4"},{"id":"2"},{"id":"5"},{"id":"6"},{"id":"7"},{"id":"10"},{"id":"9"}]
    resultType: "MA", // the type of result
    materialType: [], // the type of material
    dateModified: "", // the date the file was last modified
    URLTitle: "", // ??
    materialURL: "", // URL to the document
    slides: metadata.Previews.map(p => {
      return {
        relevance: 0,
        slideNumber: p.pageNumber,
        imageRef: p.medium.hqUrl,
        htmlUrl: p.html.hqUrl
      }
    }),
    currentSlideNumber: 0, // array of slide objects, see Slides Object below
    attachmentFileType: "docx", // ??
    previewAvailable: true, // whether or not sldies are available to view
    searchAbstract: "searchAbstract", // the description of the file
    hasSlides: true, // flags whether we already have the relevant slides
    currentSlideIndex: 0 // when hasSlides is true, this will set the slide to show by index
  }
  return doc;
};