import React, { useState, useEffect } from "react";
import "./AcknowledgementModal.scss";
import Modal from "components/shared/Modal";
import { doAxiosRequest } from "config-axios";
import { downloadFileFromUrl } from "utils/helpers/helpers";
import { getFromLocalStorageAsJSON, removeFromLocalStorage, sendToLocalStorage } from 'utils/localStorage/localStorage';
import CONFIG from "config";
import { getUserId } from "utils/auth/auth";

export const AcknowledgementModal = () => {
    const { API_KEYS: { SMP_API_KEY }, API_URL: { GET_TRANSCRIPT_ACK_API } } = CONFIG;
    const TCAcknowledged = getFromLocalStorageAsJSON("T&CAcknowledged");
    const [acknowledged, setAcknowledged] = useState(true);
    const ackButtonClick = async () => {
        try {
            const hrid = await getUserId();
            const request = {
                method: 'POST',
                endpoint: GET_TRANSCRIPT_ACK_API,
                headers: {
                    'x-api-key': SMP_API_KEY
                },
                params: {
                    "hrId": hrid,
                    "acknowledgementStatus": true
                }
            };
            const response = await doAxiosRequest(request);
            setAcknowledged(true);
        } catch (error) {
            console.error("error: ", error);
            setAcknowledged(false);
        }
    }

    useEffect(async () => {
        const hrid = await getUserId();
        try {
            const request = {
                method: 'GET',
                endpoint: GET_TRANSCRIPT_ACK_API + '/' + hrid,
                headers: {
                    'x-api-key': SMP_API_KEY
                }
            };
            const response = await doAxiosRequest(request);
            setAcknowledged(response.acknowledgementStatus);
        } catch (error) {
            console.error("error: ", error);
            setAcknowledged(false);
        }
    }, []);

    const linkClicked = () => {
        sendToLocalStorage("T&CAcknowledged", true);
        setAcknowledged(true);
    }
    return (
        <div>

            <Modal
                isOpen={!acknowledged}
                className="ack__modal"
            >
                <div className="ack__wrapper">
                    <div className="ack__heading">
                        <h2>External Experts Transcript Library Usage Acknowledgement</h2>
                    </div>
                    <div className="ack__body">
                        <div className="heading">Transcript Library was created to harness insights from the thousands of experts calls we make per year. The library enables you to download transcripts, view synthesis and summaries of key themes and offers a Q&A chat.</div>
                        <div className="sub-heading">{`While we partnered with Legal and Data Protection Teams, it's each user's responsibility to comply with the following rules:`}</div>
                        <div className="bullet-points">
                            <ul>
                                <li>You should treat the Transcript Library as any other source you use for research: to educate yourself on a topic, gather expert opinions and confirm publicly available information</li>
                                <li>You must <u>not</u> cite research from the library in any client deliverables</li>
                                <li>For BCG-led calls (via an EN vendor, such as Guidepoint, GLG etc.): you must <u>not</u> use direct quotes from transcripts in client deliverables</li>
                                <li>For Tegus-led calls: you may use quotes in client deliverables, however you must <u>not</u> attribute them to an expert (title and company must be removed)</li>
                                <li>You must <u>not</u> try to work out an {`expert's`} identity</li>
                                <li>If you would like to schedule a call with an expert, you should reach out to the respective expert network. (Note: not possible for transcript from Tegus)</li>
                                <li>If you spot any confidential information shared in a transcript, you should notify the Product Team immediately by emailing <a href="mailto:TranscriptLibrary@bcg.com">TranscriptLibrary@bcg.com</a></li>
                                <li>For general guidance on using AI tools, please visit <a onClick={() => downloadFileFromUrl("https://connect.bcg.com/responsibleai/", "_blank")}>RAI microsite</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="ack__footer">
                        <div className="description">{`By clicking "Acknowledge and Continue" you are confirming that you read, understood and agree with our terms and conditions.`}</div>
                        <div className="action-container">
                            <button className="primary" onClick={() => ackButtonClick()}>Acknowledge and Continue</button>
                        </div>
                    </div>
                </div>
            </Modal >

        </div >
    );
};
