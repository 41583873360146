import React, { useEffect, useRef, useState } from "react";
import Image from "components/shared/Image";
import "./MonthOfInterview.scss";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import { RESPONSE_DATA, ROLES, TAXONOMY } from "redux/constants";
import { useDispatch, useSelector } from "react-redux";
import { isInViewport, scrollToTop, getMonthYearFromDate } from "utils/helpers/helpers";
import { MonthRangePicker } from "../MonthRangePicker";
import monthOfInterviewIcon from "assets/images/month_of_interview_icon.svg";
import chatInfoIcon from "assets/images/chat_info_icon.svg";
import CrossIconImage from "assets/images/crossiconv1.svg";

// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";

const {
    SET_FILTERS,
    RETRIEVAL_API_RESULTS_SUCCESS,
    CLEAR_TRANSCRIPT_SELECTED,
    MONTH_OF_INTERVIEW_VALUE,
    COMPANY_FILTER_APPLIED,
    INDUSTRY_FILTER_APPLIED,
    FUNCTION_FILTER_APPLIED,
} = RESPONSE_DATA;

const {
    RESET_ALL_SL_PARENT_TAGS
} = TAXONOMY;

export const MonthOfInterviewFilter = ({ showMonthOfInterviewFilterContainer, handleFilterIconClick, monthOfInterviewRangeValue, lastUserQues }) => {

    const dispatch = useDispatch();
    const pageURL = window.location.href;
    const filters = useSelector(responseSelector.getFilters);
    const queryResponse = useSelector(responseSelector.getRetrievalResult);
    const transcriptResults = queryResponse ? [...queryResponse] : [];
    const monthOfInterviewRange = filters.monthOfInterviewRange;
    const filterData = filters.filteredIndustryTags || [];
    const filterFunctionTags = filters.filteredFunctionTags || [];
    const companyFilterApplied = useSelector(responseSelector.getCompanyFilterApplied);
    const industryFilterApplied = useSelector(responseSelector.getIndustryFilterApplied);
    const functionFilterApplied = useSelector(responseSelector.getFunctionFilterApplied);
    const enabledMonths = filters.enabledMonths;
    const initialYear = filters.initialYear;
    const sources = filters.sources || [];
    const groupedCompanies = filters.groupedCompanies || [];
    let { startMonth, endMonth } = monthOfInterviewRange;
    const actionButtonContainerElementRef = useRef(null);
    let dateFilterRef = useRef();
    const [notificationMessage, setnotificationMessage] = useState("");

    useEffect(() => {
        if (showMonthOfInterviewFilterContainer && actionButtonContainerElementRef.current) {
            const actionButtonContainer = actionButtonContainerElementRef.current;
            let windowScroll = isInViewport(actionButtonContainer);
            let existingScroll = window.scrollY;
            scrollToTop(existingScroll + windowScroll);
        }
    }, [showMonthOfInterviewFilterContainer]);

    useEffect(() => {
        let filterHandler = (e) => {
            if (!dateFilterRef.current.contains(e.target) && e.target.className !== 'filter date')
                handleFilterIconClick(false)

        }
        document.addEventListener("mousedown", filterHandler);

        return () => {
            document.removeEventListener("mousedown", filterHandler)
        }
    })

    const handleCrossClick = (e) => {
        handleFilterIconClick(false);
    }

    const handleClearClick = (e) => {

        dispatch({
            type: SET_FILTERS, payload: {
                response: {
                    monthOfInterviewRange: {
                        startMonth: null,
                        endMonth: null
                    }
                }
            }
        });
        handleApplyClick("clear");;
    }
    const handleApplyClick = (fromClear) => {
        if (fromClear !== "clear") {
            if (startMonth === null && endMonth === null) {
                setnotificationMessage("Please select the Start & End Month");
                return;
            } else if (startMonth === null) {
                setnotificationMessage("Please select the Start Month");
                return;
            } else if (endMonth === null) {
                setnotificationMessage("Please select the End Month");
                return;
            } else if (endMonth < startMonth) {
                setnotificationMessage("End Month should be greater than the Start Month");
                return;
            }
        }
        startMonth = fromClear === "clear" ? null : startMonth;
        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Transcript_Library_Keyword_Search,
                    previousPageName: PAGES.Transcript_Library_Keyword_Search,
                    pageURL: pageURL
                }
            },
            internal: {
                search: { term: lastUserQues }
            }
        };
        newAdobeData.filterCategory = "Month of Interview";
        newAdobeData.filterChecked = "";

        handleFilterIconClick(false);
        //dispatch({ type: QUERY_RESPONSE, payload: { question: latestSearchText, filters, dispatch } });
        dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });
        dispatch({ type: RESET_ALL_SL_PARENT_TAGS, payload: { response: "" } });

        transcriptResults.forEach((transcript) => {
            const transcriptMonth = new Date(transcript.date_of_interview * 1000).getMonth();
            const transcriptYear = new Date(transcript.date_of_interview * 1000).getFullYear();
            if (startMonth !== null) {
                if (endMonth === null) {
                    transcript.isHide = startMonth.getMonth() !== transcriptMonth || startMonth.getFullYear() !== transcriptYear;
                } else {
                    const startYearMonth = startMonth.getFullYear() + '' + ('0' + startMonth.getMonth()).slice(-2);
                    const endYearMonth = endMonth.getFullYear() + '' + ('0' + endMonth.getMonth()).slice(-2);
                    const transcriptYearMonth = transcriptYear + '' + ('0' + transcriptMonth).slice(-2);
                    if (startYearMonth > transcriptYearMonth || endYearMonth < transcriptYearMonth) {
                        transcript.isHide = true;
                    } else {
                        transcript.isHide = false;
                    }
                }
                const value = getMonthYearFromDate(startMonth) + " - " + getMonthYearFromDate(endMonth || startMonth);
                dispatch({ type: MONTH_OF_INTERVIEW_VALUE, payload: { response: value } });
                newAdobeData.filterChecked = value;
            } else {
                transcript.isHide = false;
                dispatch({ type: MONTH_OF_INTERVIEW_VALUE, payload: { response: "" } });
            }
        });

        const selectedTranscripts = transcriptResults.filter(tr => !tr.isHide && !tr.isHideCF && !tr.isHideSF && !tr.isHideIF && !tr.isHideFF);
        const dummySources = {};
        selectedTranscripts.forEach((transcript) => {
            if (dummySources[transcript.vendor_source]) {
                dummySources[transcript.vendor_source].push(transcript.meeting_id);
            } else {
                dummySources[transcript.vendor_source] = [transcript.meeting_id];
            }
        })
        const newSourceList = [...sources];
        newSourceList.map(status => {
            status.value = status.key + " (" + ((dummySources[status.key] && dummySources[status.key].length) || 0) + ")";
            status.transcriptIds = dummySources[status.key] || [];
            status.show = dummySources[status.key] ? dummySources[status.key].length > 0 : false;
        })


        const availableCompanies = []
        selectedTranscripts.forEach((transcript) => {
            const expert_employment_history = transcript.expert_employment_history;
            expert_employment_history && expert_employment_history.length > 0 &&
                expert_employment_history.forEach(eeh => availableCompanies.push(eeh.company));
        })

        const companyList = [];

        const newGroupedCompanies = [...groupedCompanies];
        newGroupedCompanies.map(group => {
            group.children.map(status => {
                const ifMatches = availableCompanies.indexOf(status.key.substring(0, status.key.lastIndexOf(" ("))) > -1;
                status.filterShow = ifMatches;
                if (status.selected && ifMatches) {
                    companyList.push(status.key.substring(0, status.key.lastIndexOf(" (")));
                }
            })
            group.filterShow = group.children.filter(status => status.filterShow).length > 0;
        })
        dispatch({
            type: SET_FILTERS, payload: {
                newGroupedCompanies
            }
        });

        const enabledMonths = [...new Set(selectedTranscripts.map(i => new Date(i.date_of_interview * 1000).getMonth() + "" + new Date(i.date_of_interview * 1000).getFullYear()))];
        //const initialYear = enabledMonths.map(i => i.substring(i.length === 5 ? 1 : 2)).sort()[0];
        dispatch({
            type: SET_FILTERS, payload: {
                response: {
                    enabledMonths
                }
            }
        });

        if (companyFilterApplied > 0) {
            dispatch({ type: COMPANY_FILTER_APPLIED, payload: { response: companyList.length } });
        }

        const allTranscriptSelected = selectedTranscripts.length === transcriptResults.length;
        let newFilterData = [...filterData];
        const selectedMeetingIds = selectedTranscripts.map(tr => tr.meeting_id);
        let tagsSelected = [];
        newFilterData.map((filter) => {
            if (selectedMeetingIds.filter(id => filter.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                filter.filterShow = true;
                if (filter.visible === true) {
                    tagsSelected.push(filter.tagName);
                }
            } else {
                filter.filterShow = false;
                filter.checked = false;
            }
            filter.children && filter.children.length > 0 && filter.children.map(filterLevel1 => {
                if (selectedMeetingIds.filter(id => filterLevel1.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                    filterLevel1.filterShow = true;
                    if (filterLevel1.visible === true) {
                        tagsSelected.push(filterLevel1.tagName);
                    }
                } else {
                    filterLevel1.filterShow = false;
                    filterLevel1.checked = false;
                }
                filterLevel1.children && filterLevel1.children.length > 0 && filterLevel1.children.map(filterLevel2 => {
                    if (selectedMeetingIds.filter(id => filterLevel2.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                        filterLevel2.filterShow = true;
                        if (filterLevel2.visible === true) {
                            tagsSelected.push(filterLevel2.tagName);
                        }
                    } else {
                        filterLevel2.filterShow = false;
                        filterLevel2.checked = false;
                    }
                })
            })
        })
        dispatch({ type: SET_FILTERS, payload: { filteredIndustryTags: newFilterData } });
        industryFilterApplied > 0 && dispatch({ type: INDUSTRY_FILTER_APPLIED, payload: { response: tagsSelected.length } });

        newFilterData = [...filterFunctionTags];
        tagsSelected = [];
        newFilterData.map((filter) => {
            if (selectedMeetingIds.filter(id => filter.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                filter.filterShow = true;
                if (filter.visible === true) {
                    tagsSelected.push(filter.tagName);
                }
            } else {
                filter.filterShow = false;
                filter.checked = false;
            }
            filter.children && filter.children.length > 0 && filter.children.map(filterLevel1 => {
                if (selectedMeetingIds.filter(id => filterLevel1.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                    filterLevel1.filterShow = true;
                    if (filterLevel1.visible === true) {
                        tagsSelected.push(filterLevel1.tagName);
                    }
                } else {
                    filterLevel1.filterShow = false;
                    filterLevel1.checked = false;
                }
                filterLevel1.children && filterLevel1.children.length > 0 && filterLevel1.children.map(filterLevel2 => {
                    if (selectedMeetingIds.filter(id => filterLevel2.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                        filterLevel2.filterShow = true;
                        if (filterLevel2.visible === true) {
                            tagsSelected.push(filterLevel2.tagName);
                        }
                    } else {
                        filterLevel2.filterShow = false;
                        filterLevel2.checked = false;
                    }
                })
            })
        })
        dispatch({ type: SET_FILTERS, payload: { filteredFunctionTags: newFilterData } });
        functionFilterApplied > 0 && dispatch({ type: FUNCTION_FILTER_APPLIED, payload: { response: tagsSelected.length } });

        dispatch({ type: SET_FILTERS, payload: { sources: newSourceList } });
        ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.FILTER_CLICKS);
        dispatch({ type: RETRIEVAL_API_RESULTS_SUCCESS, payload: { response: transcriptResults } });
    }

    return (
        <div ref={dateFilterRef} className="date-filter-container">
            <div className='filter-container'>
                {notificationMessage !== "" &&
                    <div className="notification-container">
                        <div className="notification">
                            <Image src={chatInfoIcon} alt="logo" />
                            <span className="label-text">{notificationMessage}</span>
                        </div>
                    </div>
                }
                <div className={`${notificationMessage !== "" ? "header top-30" : "header"}`}>
                    <Image className="cross-image" src={CrossIconImage} alt="CrossIconImage" onClick={handleCrossClick} />
                </div>
                <div className="month-range-picker-wrapper">
                    <div className="month-range-picker-container">
                        <div className="month-label-container">
                            <div className="month-label">
                                <Image src={monthOfInterviewIcon} alt="logo" />
                                <span className="label-text">Start Month</span>
                            </div>
                        </div>
                        <MonthRangePicker
                            initialYear={initialYear}
                            startMonth={startMonth}
                            endMonth={endMonth}
                            enabledMonths={enabledMonths}
                            monthOfInterviewRangeValue={monthOfInterviewRangeValue}
                            calendarClassName={"month-range-picker-parent"}
                            headerClassName={"month-range-picker-header"}
                            monthClassName={"month-range-picker-cell"}
                            type={'start-month-picker'} />
                    </div>
                    <div className="month-range-picker-container">
                        <div className="month-label-container">
                            <div className="month-label">
                                <Image src={monthOfInterviewIcon} alt="logo" />
                                <span className="label-text">End Month</span>
                            </div>
                        </div>
                        <MonthRangePicker
                            initialYear={initialYear}
                            startMonth={startMonth}
                            endMonth={endMonth}
                            enabledMonths={enabledMonths}
                            monthOfInterviewRangeValue={monthOfInterviewRangeValue}
                            calendarClassName={"month-range-picker-parent"}
                            headerClassName={"month-range-picker-header"}
                            monthClassName={"month-range-picker-cell"}
                            type={'end-month-picker'} />
                    </div>
                </div>
                <div className="filter-separator"></div>
                <div className="filter-action-item" ref={actionButtonContainerElementRef}>
                    <button className="btn secondary button" onClick={handleClearClick}>Clear</button>
                    <button className="btn primary button" onClick={handleApplyClick}>Apply Now</button>
                </div>
            </div>
        </div >

    )
};
