// React
import React from 'react';
import { Button } from "components/shared/Button";
import bulb from "assets/images/bulb.svg";
import Image from "components/shared/Image";
import CONFIG from 'config';
// scss
import './Error.scss';

export const Error = ({ code, btnClick, btn2Click }) => {
  const { ERROR_CONFIG = {} } = CONFIG;
  const { HEADING, MESSAGE, HELP_TEXT, BTN_LABEL, BTN_LINK, BTN2_LABEL, BTN2_LINK, IMAGE_HEADING, BTN_LINK_NEW } = ERROR_CONFIG[code] || {};
  return (
    <div className="errorWrapper">
      <h1 className="errorWrapper__heading">
        {!!IMAGE_HEADING && IMAGE_HEADING === "bulb" ?
          (<>
            4
            <Image
              className="errorWrapper_bulb"
              src={bulb}
              alt={"bulb"}
            />
            1
          </>
          ) : HEADING}
      </h1>
      {!!MESSAGE && <div className="errorWrapper__message">{MESSAGE}</div>}
      {!!HELP_TEXT && <div className="errorWrapper__helpText">{HELP_TEXT}</div>}
      {
        !!BTN_LABEL &&
        <a target={BTN_LINK_NEW ? "_blank" : "_self"} rel="noopener noreferrer" href={BTN_LINK} onClick={btnClick}>
          <Button className="errorWrapper__btn green">{BTN_LABEL}</Button>
        </a>
      }
      {
        !!BTN2_LABEL &&
        <a rel="noopener noreferrer" href={BTN2_LINK} onClick={btn2Click} >
          <Button className="errorWrapper__btn green_trans">{BTN2_LABEL}</Button>
        </a>
      }
    </div>
  )
}

export default Error;