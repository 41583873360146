// import { getCookie } from "utils/cookies/cookies";
import { put, takeLatest, all, call, takeEvery } from 'redux-saga/effects';
// Constant
import { TAXONOMY } from "redux/constants";
// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
import {
    getTaxonomyPending,
    getTaxonomySuccess,
    getTaxonomyFailure,
    getTypeSearchPending,
    getTypeSearchFailure,
    getTypeSearchSuccess,
    getSLparentTagsPending,
    getSLparentTagsSuccess,
    getSLparentTagsFailure
} from "./Taxonomy.actions";
import { moduleFailure } from "redux/global.action.js";
import { getFromLocalStorageAsJSON } from 'utils/localStorage/localStorage';
import { filterRegionsDataByGlobal, filterInternalOfficeDataByGlobal } from "utils/helpers/helpers";
import { getSmartLogicBearerToken } from "../authorization/authorization.saga";

export function* getTaxonomy(action) {
    const { payload: { key: taxonomyName } } = action;
    try {
        const { XA, API_URL: { GET_KNOWLEDGE_TAXONOMY, GET_TAXONOMY_ODATA, GET_OFFICES } } = CONFIG;
        yield put(getTaxonomyPending(taxonomyName));
        let paramurl;

        if (taxonomyName === "TOPICPAGE_IPA" || taxonomyName === "TOPICPAGE_FPA") {
            paramurl = GET_KNOWLEDGE_TAXONOMY(CONFIG.TAXONOMY_IDS[taxonomyName]());
        } else if (taxonomyName === "INTERNAL_OFFICE_COUNTRIES") {
            paramurl = GET_OFFICES;
        } else {
            paramurl = GET_TAXONOMY_ODATA(CONFIG.TAXONOMY_IDS[taxonomyName]());
        }

        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: paramurl,
            headers: {
                'x-api-key': XA
            }
        });

        const { value, error } = res;
        let response = res;
        let newvalue;
        if (taxonomyName === "REGIONS_COUNTRIES") {
            newvalue = filterRegionsDataByGlobal(value, "Id");
            response = { value: newvalue, error: error };
        } else if (taxonomyName === "INTERNAL_OFFICE_COUNTRIES") {
            newvalue = filterInternalOfficeDataByGlobal(value, "Id");
            response = { value: newvalue, error: error };
        }

        yield put(getTaxonomySuccess(taxonomyName, response));

    } catch (error) {
        yield put(getTaxonomyFailure(taxonomyName, error));
    }
}

export function* getTypeSearchData(action) {
    try {
        const { payload: { term, typeSearchKey } } = action;
        const { XA, OKTA: { STK }, API_URL: { GET_TYPESEARCH_DATA } } = CONFIG;
        const smartLogicAcessToken = getFromLocalStorageAsJSON(STK);
        yield put(getTypeSearchPending());
        const axiosConfig = {
            method: "get",
            headers: {
                'customAuthHeader': true,
                'noAuthHeader': true,
                'authorization': `Bearer ${smartLogicAcessToken}`,
                'x-api-key': XA
            }
        };
        const responses = yield all(typeSearchKey.map(item => {
            axiosConfig["endpoint"] = GET_TYPESEARCH_DATA(CONFIG.TBDB_IDS[item.searchKey], term);
            return call(doAxiosRequest, axiosConfig);
        }));
        let combinedArray = [];
        responses.forEach(res => {
            combinedArray = [...combinedArray, ...res.data.termHints];
        });
        yield put(getTypeSearchSuccess(combinedArray));
    } catch (error) {
        if (error?.error?.response?.status === 403 || error?.error?.response?.status === 401) {
            yield call(getSmartLogicBearerToken);
        } else {
            yield put(getTypeSearchFailure(error));
        }
    }
};

export function* getSLparentTagsData(action) {
    try {
        const { payload: { ids, key, needParentalRoot = false } } = action;
        const { XA, OKTA: { STK }, API_URL: { GET_SL_PARENT_TAGS } } = CONFIG;
        const smartLogicAcessToken = getFromLocalStorageAsJSON(STK);
        yield put(getSLparentTagsPending());
        const paramURL = GET_SL_PARENT_TAGS(key, ids);
        const axiosConfig = {
            method: "get",
            headers: {
                'customAuthHeader': true,
                'noAuthHeader': true,
                'authorization': `Bearer ${smartLogicAcessToken}`,
                'x-api-key': XA
            },
            endpoint: paramURL
        };
        let res = yield call(doAxiosRequest, axiosConfig);
        yield put(getSLparentTagsSuccess(res, key, needParentalRoot));
    } catch (error) {
        if (error?.error?.response?.status === 403 || error?.error?.response?.status === 401) {
            yield call(getSmartLogicBearerToken);
            yield call(getSLparentTagsData, action);
        } else {
            yield put(getSLparentTagsFailure(error));
        }
    }
};

export default function* TaxonomySaga() {
    try {
        yield takeEvery(TAXONOMY.GET_TAXONOMY, getTaxonomy);
        yield takeLatest(TAXONOMY.GET_TYPE_SEARCH_DATA, getTypeSearchData);
        yield takeEvery(TAXONOMY.GET_SL_PARENT_TAGS_DATA, getSLparentTagsData);

    } catch (error) {
        yield put(moduleFailure(error, 'TaxonomySaga'));
    }
}