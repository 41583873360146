// Constant
import { USER_SESSION } from "redux/constants";

export const getActiveUserSessionPending = () => ({
    type: USER_SESSION.ACTIVE_USER_SESSION_PENDING,
});

export const getActiveUserSessionFailure = (error) => ({
    type: USER_SESSION.ACTIVE_USER_SESSION_FAILURE,
    payload: {
        error: error
    }
});

export const getActiveUserSessionSuccess = (data) => ({
    type: USER_SESSION.ACTIVE_USER_SESSION_SUCCESS,
    payload: {
        data
    }
});

export const getActiveUserSession = () => ({
    type: USER_SESSION.GET_ACTIVE_USER_SESSION,
});