import CONFIG from 'config';
import { datadogRum } from '@datadog/browser-rum';

const {
  DD: {
    AD,
    CT,
    AN
  },
  ENVIRONMENT_MAIN,
  TRANSCRIPT_LIBRARY_VERSION
} = CONFIG

if (ENVIRONMENT_MAIN !== 'local') {
  datadogRum.init({
    applicationId: AD,
    clientToken: CT,
    service: AN,
    env: ENVIRONMENT_MAIN,
    version: TRANSCRIPT_LIBRARY_VERSION,
    site: 'datadoghq.com',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 80,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
  datadogRum.startSessionReplayRecording();
}
