import { connectRouter, routerMiddleware } from "connected-react-router";
import { combineReducers } from "redux";
import { createStore } from "redux";
import history from "redux/history";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose } from "redux";

import {
  name as globalReducerName,
  reducer as globalReducer,
} from "./reducers/global/global_reducer";

import {
  name as notifyReducerName,
  reducer as notifyReducer,
} from "./reducers/notification/notification_reducer";

import {
  name as responseReducerName,
  reducer as responseReducer,
} from "./reducers/response/response_reducer";

import {
  name as docvizReducerName,
  reducer as docvizReducer,
} from "./reducers/docviz/docviz_reducer";

import {
  name as suggestedQueryReducerName,
  reducer as suggestedQueryReducer,
} from "./reducers/suggested_query/suggestedquery_reducer";

import {
  name as userSessionName,
  reducer as userSessionReducer,
} from "./reducers/usersession/usersession_reducer";

import {
  name as taxonomyReducerName,
  reducer as taxonomyReducer,
} from "./reducers/taxonomy/taxonomy_reducer";

import {
  name as authorizationReducerName,
  reducer as authorizationReducer,
} from "./reducers/authorization/authorization_reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const middleware = [
  thunkMiddleware,
  sagaMiddleware,
  routerMiddleware(history),
  createLogger({ collapsed: true }),
];

const store = createStore(
  combineReducers({
    router: connectRouter(history),
    [notifyReducerName]: notifyReducer,
    [globalReducerName]: globalReducer,
    [responseReducerName]: responseReducer,
    [docvizReducerName]: docvizReducer,
    [suggestedQueryReducerName]: suggestedQueryReducer,
    [userSessionName]: userSessionReducer,
    [authorizationReducerName]: authorizationReducer,
    [taxonomyReducerName]: taxonomyReducer,
  }),
  composeEnhancers(applyMiddleware(...middleware))
);
const runSaga = sagaMiddleware.run;
export { store, runSaga };
