// constant
import { ENTITLEMENTS, AUTH } from "redux/constants";
import CONSTANTS from "globalConstants";
// Config
import CONFIG from "config";

const {
  API_STATUS: {
    START
  }
} = CONSTANTS;

// local variable
export const name = "entitlements";
// initial state
export const initialState = {
  products: [],
  apiCallStatus: START,
  isSiteAdmin: false,
  error: "",
  bearerToken: {
    loading: false,
    isBearerTokenSuccess: false,
  },
};

const getProductEntitlements = (products, productName) => {
  let entitlements = false;
  if (Array.isArray(products)) {
    // iterate the product
    products.forEach((product) => {
      // match
      const { ENTITLEMENTS: { PRODUCTID } } = CONSTANTS;
      if (product?.productId === CONFIG.ENTITLEMENTS[productName][PRODUCTID]) {
        entitlements = product?.entitlements;
        return;
      }
    });
  }
  return entitlements;
}

const getEntitlementValue = (products, productName, entitlementFlag) => {
  const {
    ENTITLEMENTS: {
      ALL
    }
  } = CONSTANTS;
  const entitlements = getProductEntitlements(products, productName);

  let entitlementFlagsValue = {};
  let entitlementFlagValue = '';

  if (Array.isArray(entitlements)) {
    // iterate on the entitlements of the product passed in productName
    entitlements.forEach((entitlement) => {
      if (entitlementFlag === ALL) {
        entitlementFlagsValue[entitlement.entitlementId] = entitlement.valid;
      } else if (Array.isArray(entitlementFlag)) {
        const entitlementIds = entitlementFlag.map(entitlement => CONFIG.ENTITLEMENTS[productName][entitlement]);
        if (entitlementIds.includes(entitlement.entitlementId))
          entitlementFlagsValue[entitlement.entitlementId] = entitlement.valid;
      } else {
        if (entitlement?.entitlementId === CONFIG.ENTITLEMENTS[productName][entitlementFlag]) {
          entitlementFlagValue = entitlement.valid;
        }
      }
    });
    return entitlementFlagValue !== '' ? entitlementFlagValue : entitlementFlagsValue;
  }
}

// reducer selectors
export const selectors = {
  getEntitlementValue: (state, productName, entitlementFlag) => getEntitlementValue(state[name].products, productName, entitlementFlag),
  getApiCallStatus: (state) => state[name].apiCallStatus,
  checkSiteAdmin: (state) => state[name].isSiteAdmin,
  getTokenSuccessStatus: (state) => state[name].bearerToken.isBearerTokenSuccess,
  getTokenLoadingStatus: (state) => state[name].bearerToken.loading
};

const setEntitlements = (state, payload) => {
  return {
    ...state,
    products: payload?.res,
    apiCallStatus: payload?.apiCallStatus
  }
};

const setIsSiteAdmin = (state, payload) => {
  return {
    ...state,
    isSiteAdmin: payload.res,
    isLoading: false
  }
}

const setIsLoading = (state) => {
  return {
    ...state,
    isLoading: true,
    error: "",
  }
}

const setError = (state, payload) => {
  return {
    ...state,
    error: payload.error,
    isLoading: false,
  }
}

const setBearerTokenForPending = (state, payload) => {
  const { bearerToken } = state;
  return Object.assign({}, state, {
    ...state,
    isLoading: true,
    error: "",
    bearerToken: {
      ...bearerToken,
      loading: true,
    },
  });
}

const setBearerTokenForSuccess = (state, payload) => {
  const { response } = payload;
  const { bearerToken } = state;
  return Object.assign({}, state, {
    isLoading: false,
    error: "",
    bearerToken: {
      ...bearerToken,
      loading: false,
      isBearerTokenSuccess: response
    },
  });
}

export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ENTITLEMENTS.API_GET_ENTITLEMENTS_PENDING:
      return setEntitlements(state, payload);
    case ENTITLEMENTS.API_GET_ENTITLEMENTS_SUCCESS:
      return setEntitlements(state, payload);
    case ENTITLEMENTS.API_GET_ENTITLEMENTS_FAILURE:
      return setEntitlements(state, payload);
    case ENTITLEMENTS.API_CHECK_SITE_ADMIN_PENDING:
      return setIsLoading(state, payload);
    case ENTITLEMENTS.API_CHECK_SITE_ADMIN_SUCCESS:
      return setIsSiteAdmin(state, payload);
    case ENTITLEMENTS.API_CHECK_SITE_ADMIN_FAILURE:
      return setError(state, payload);
    case AUTH.GET_SMARTLOGIC_BEARER_TOKEN_PENDING:
      return setBearerTokenForPending(state);
    case AUTH.GET_SMARTLOGIC_BEARER_TOKEN_FAILURE:
      return setError(state, payload);
    case AUTH.GET_SMARTLOGIC_BEARER_TOKEN_SUCCESS:
      return setBearerTokenForSuccess(state, payload);
    default:
      return state;
  }
}
