// Constant
import { TAXONOMY } from "redux/constants";

export const getTaxonomyPending = (taxonomyName) => ({
    type: TAXONOMY.API_GET_TAXONOMY_PENDING,
    payload: {
        taxonomyName,
        res: ''
    }
});

export const getTaxonomySuccess = (taxonomyName, res) => ({
    type: TAXONOMY.API_GET_TAXONOMY_SUCCESS,
    payload: {
        taxonomyName,
        res: res.value
    }
});

export const getTaxonomyFailure = (taxonomyName, error) => ({
    type: TAXONOMY.API_GET_TAXONOMY_FAILURE,
    payload: {
        taxonomyName,
        res: error.toString()
    }
});

export const getTypeSearchPending = () => ({
    type: TAXONOMY.API_GET_TYPESEARCH_PENDING,
    payload: "",
});

export const getTypeSearchSuccess = (combinedArray) => ({
    type: TAXONOMY.API_GET_TYPESEARCH_SUCCESS,
    payload: combinedArray,
});

export const getTypeSearchFailure = (error) => ({
    type: TAXONOMY.API_GET_TYPESEARCH_FAILURE,
    payload: { Error: error.toString() },
});


export const getSLparentTagsPending = () => ({
    type: TAXONOMY.GET_SL_PARENT_TAGS_PENDING,
    payload: "",
});

export const getSLparentTagsSuccess = (res, key, needParentalRoot) => ({
    type: TAXONOMY.GET_SL_PARENT_TAGS_SUCCESS,
    payload: { data: res?.data?.browseTerms, key, isShiftFinalLeaf: needParentalRoot },
});

export const getSLparentTagsFailure = (error) => ({
    type: TAXONOMY.GET_SL_PARENT_TAGS_FAILURE,
    payload: { Error: error.toString() },
});

export const resetAllParentTags = () => ({
    type: TAXONOMY.RESET_ALL_SL_PARENT_TAGS
});