import { OktaAuth } from '@okta/okta-auth-js';
import CONFIG from 'config';

const {
  // ENVIRONMENT_MAIN,
  OKTA: {
    ISSUER,
    CLIENT_ID,
    REDIRECT_URL
  }
} = CONFIG;

// const baseConfig = utils.authServiceConfig(ENVIRONMENT_MAIN);
const authConfig = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: `${window.location.origin}${REDIRECT_URL}`,
  pkce: true,
  scopes: ["profile", "email", "openid", "groups"]
}

export default new OktaAuth({ ...authConfig });
