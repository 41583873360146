import React from "react";
import { Form } from "semantic-ui-react";
import PropTypes from "prop-types";
import { TextAreaCounter } from 'components/TextAreaCounter/TextAreaCounter';
import { Button } from "components/shared/Button";
import thumbsup from 'assets/images/thumbsup.svg';
import thumbsdown from 'assets/images/thumbsdown.svg';
import thumbsupactive from 'assets/images/thumbsupactive.svg';
import thumbsdownactive from 'assets/images/thumbsdownactive.svg';
import crossIcon from 'assets/images/crossiconv1.svg';

import Checkbox from "components/shared/Checkbox";
import { ArrowPopup } from "components/shared/ArrowPopup";
import LABELS from "labels";
import Image from "components/shared/Image";
import "./ThumbsUpDown.scss";

const hideSuccessTime = 3000;

class ThumbsUpDown extends React.Component {
  constructor(props) {
    super(props);
    const baseQuestionAreState = this.getBaseQuestionareState();
    this.state = {
      iconUpActive: false,
      iconDownActive: false,
      thumbsUpsuccess: false,
      thumbsDownsuccess: false,
      thumbsDownQuestionare: false,
      ...baseQuestionAreState
    }
  }
  getBaseQuestionareState = () => {
    return {
      comment: "",
      isReason1: false,
      isReason2: false,
      isReason3: false,
      isReason4: false,
      isSubmitDisable: true,
      isShowTextArea: false
    }
  }

  setThumbsUpsuccess = (isOpen) => {
    const baseQuestionAreState = this.getBaseQuestionareState();
    const { finalSuccess } = this.props;
    if (!!finalSuccess) {
      finalSuccess();
    }
    this.setState({
      thumbsUpsuccess: isOpen,
      iconUpActive: isOpen,
      iconDownActive: false,
      thumbsDownsuccess: false,
      thumbsDownQuestionare: false,
      ...baseQuestionAreState
    }, () => {
      if (isOpen) {
        setTimeout(() => {
          this.setState({
            thumbsUpsuccess: false
          });
        }, hideSuccessTime);
      }
    });

  }

  setThumbsDownsuccess = (isOpen) => {
    const {
      THUMBSUPDOWN: {
        THUMBSDOWN
      }
    } = LABELS;
    const baseQuestionAreState = this.getBaseQuestionareState();
    const { finalSuccess } = this.props;
    if (!!finalSuccess) {
      finalSuccess(THUMBSDOWN);
    }
    this.setState({
      thumbsUpsuccess: false,
      iconUpActive: false,
      iconDownActive: isOpen,
      thumbsDownsuccess: isOpen,
      thumbsDownQuestionare: false,
      ...baseQuestionAreState
    }, () => {
      if (isOpen) {
        setTimeout(() => {
          this.setState({
            thumbsDownsuccess: false
          });
        }, hideSuccessTime);
      }
    });

  }

  OpenThumbsDownQuestionare = (isOpen) => {
    this.setState({
      thumbsDownQuestionare: isOpen,
      // iconDownActive: isOpen,
      iconUpActive: false,
      thumbsUpsuccess: false
    });
  }

  closeThumbsDownQuestionare = () => {
    const { iconDownActive } = this.state;
    this.setState({
      thumbsDownQuestionare: false,
      iconDownActive: iconDownActive ? iconDownActive : false
    });
  }

  prepareSelectedReasonData = () => {
    const { comment, isReason1, isReason2, isReason3, isReason4 } = this.state;
    const {
      THUMBSUPDOWN: { REASON_1, REASON_2, REASON_3, REASON_4, }
    } = LABELS;
    let selectedReason = [];
    isReason1 && selectedReason.push(REASON_1)
    isReason2 && selectedReason.push(REASON_2)
    isReason3 && selectedReason.push(REASON_3)
    isReason4 && selectedReason.push(REASON_4)
    !!comment && selectedReason.push(comment)
    return selectedReason.join("|")
  }

  submitHandler = () => {
    const { finalSuccess } = this.props;
    if (!!finalSuccess) {
      let selectedOptionReason = this.prepareSelectedReasonData();
      finalSuccess(selectedOptionReason);
    }
    this.setState({
      thumbsDownQuestionare: false,
      iconDownActive: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          thumbsDownsuccess: true
        }, () => {
          setTimeout(() => {
            this.setState({
              thumbsDownsuccess: false
            });
          }, hideSuccessTime);
        });
      }, 1000);
    });
  }
  handleChange = (value, stateName) => {
    const that = this;
    this.setState({
      [stateName]: value
    }, () => {
      let isDisable = true;
      const { isReason1, isReason2, isReason3, isReason4 } = that.state;
      if (isReason1 || isReason2 || isReason3 || isReason4) {
        isDisable = false;
      }
      this.setState({
        isSubmitDisable: isDisable
      });

      if (stateName === "isReason4") {
        this.setState({
          isShowTextArea: value
        });
      }
    });

  }
  getQuestionModal = () => {
    const {
      isReason1,
      isReason2,
      isReason3,
      isReason4,
      isShowTextArea,
      isSubmitDisable,
      comment
    } = this.state;
    const {
      THUMBSUPDOWN: {
        HEADING,
        REASON_1,
        REASON_2,
        REASON_3,
        REASON_4,
        TEXTAREA_PLACEHOLDER,
        SUBMIT
      }
    } = LABELS;
    const questionareObj = [
      { label: REASON_1, name: "reason1", state: isReason1, stateName: "isReason1" },
      { label: REASON_2, name: "reason2", state: isReason2, stateName: "isReason2" },
      { label: REASON_3, name: "reason3", state: isReason3, stateName: "isReason3" },
      { label: REASON_4, name: "reason4", state: isReason4, stateName: "isReason4" }
    ];
    return (
      <div className="questionare__wrap">
        <h5>{HEADING}</h5>
        <Image src={crossIcon} className="questionare__crossicon" onClick={this.closeThumbsDownQuestionare} />
        <Form.Group>
          <Form.Field>
            {questionareObj.map(
              ({ label, name, state, stateName }) => {
                return (
                  <Checkbox
                    onChange={() => this.handleChange(!state, stateName)}
                    checked={state}
                    name={name}
                    label={label}
                    className="questionare__checkbox custom__checkbox"
                  />
                )
              }
            )}
            {isShowTextArea && (<TextAreaCounter
              maxLength={1000}
              placeHolder={TEXTAREA_PLACEHOLDER}
              handleChangeCallback={(val) => this.handleChange(val, "comment")}
              prefillValue={comment}
            />
            )}
            <div className="questionare__buttonwrap">
              <Button className="questionare__button green" disabled={isSubmitDisable} onClick={this.submitHandler}>{SUBMIT}</Button>
            </div>
          </Form.Field>
        </Form.Group>

      </div>
    )
  }
  thankYouMessage = () => {
    const {
      THUMBSUPDOWN: {
        THANKYOU,
        THANKYOU_PARA
      }
    } = LABELS;
    return (
      <>
        <h5>{THANKYOU}</h5>
        <p>{THANKYOU_PARA}</p>
      </>
    )
  }
  render() {
    const { isThumbsUpDownV2 } = this.props;
    const { thumbsUpsuccess, thumbsDownsuccess, iconUpActive, iconDownActive, thumbsDownQuestionare } = this.state;
    return (
      <>
        <div className="thumbsupdown__wrap" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
          <ArrowPopup
            trigger={<Image src={iconUpActive ? thumbsupactive : thumbsup} className="thumbsupdown__img" />}
            onOpen={() => this.setThumbsUpsuccess(true)}
            open={thumbsUpsuccess}
            on='click'
            position='top right'
          >
            {this.thankYouMessage()}
          </ArrowPopup>
          |
          {isThumbsUpDownV2 ?
          <ArrowPopup
            trigger={<Image src={iconDownActive ? thumbsdownactive : thumbsdown} className="thumbsupdown__img thumbsupdown__img--topmargin" />}
            onOpen={() => this.setThumbsDownsuccess(true)}
            open={thumbsDownsuccess}
            on='click'
            position='top right'
          >
            {this.thankYouMessage()}
          </ArrowPopup>
          :
          <ArrowPopup
            trigger={<Image src={iconDownActive || thumbsDownQuestionare ? thumbsdownactive : thumbsdown} className="thumbsupdown__img thumbsupdown__img--topmargin" />}
            onOpen={() => this.OpenThumbsDownQuestionare(true)}
            open={thumbsDownQuestionare || thumbsDownsuccess}
            on='click'
            basic={!thumbsDownsuccess}
            position={thumbsDownsuccess ? 'top right' : "bottom right"}
            className="questionare"
            pinned
          >
            {thumbsDownsuccess ? this.thankYouMessage() : this.getQuestionModal()}
          </ArrowPopup>
          }
        </div>
      </>
    );
  }
}

ThumbsUpDown.prototypes = {
  finalSuccess: PropTypes.func
}

export { ThumbsUpDown };
