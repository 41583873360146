const LABELS = {
  CHAT_EMPTY_TITLE: "External Experts Transcript Library",
  CHAT_EMPTY_SUBTITLE: "Let's get started...",
  INPUT_EMPTY_HEADING: "Ask any question or select from above prompts",
  INPUT_HEADING: "Ask any question",
  CITATIONS_HEADING: "Citations:",
  CLOSE: "Close",
  THUMBSUPDOWN: {
    THANKYOU: "Thank you!",
    THANKYOU_PARA: "This will help us improve the content quality.",
    HEADING: "How would you rate your content quality experience on this collection?",
    REASON_1: "Not relevant to this page",
    REASON_2: "Outdated Content",
    REASON_3: "Poor attachment quality",
    REASON_4: "Other",
    TEXTAREA_PLACEHOLDER: "Please provide the reason for your dissatisfaction",
    SUBMIT: "Submit",
    HEADING_KP: "Rate this Content",
    HEADING_FEEDBACK: "Is this helpful?",
    THUMBSUP: "thumbs up",
    THUMBSDOWN: "thumbs down"
  },
  TAXONOMY: {
    TOPIC_LABEL:
      "Which PA/Topic Page would you like to display this Bundle on?",
    TOPIC_PLACEHOLDER: "Please add PA/Topic Page",
    TOPIC_MODAL_HEADING:
      "Which PA/Topic Page would you like to display this Bundle on?",

    IPA_FPA_LABEL: "Select PA Sectors/Topics",
    IPA_FPA_TIPTEXT: "Respective Sector/Topic owners are notified of changes to this Knowledge Bundle",
    IPA_FPA_PLACEHOLDER: "Select PA Sectors/Topics",
    IPA_FPA_MODAL_HEADING: "Select PA Sectors/Topics",
    IPA_FPA_MODAL_SEARCH_PLACEHOLDER: "Search Practice area",

    SUBJECT_LABEL: "Search and Discovery Tags",
    SUBJECT_PLACEHOLDER: "Eg: Industrial Goods",
    SUBJECT_MODAL_HEADING: "Search and Discovery Tags",
    SEARCH: "Search",
    SEARCH_SUBJECT_PLACEHOLDER: "Search Subject",
    SECTION_ADMIN_HEADING: "Administrative & Reporting Tags",
    TYPESEARCH_PLACEHOLDER: "Search tags",
    AUTOTAGGED_MESSAGE: "Tag added as the content is associated to a specific Geo Knowledge Team, as per case number.",
    AUTOTAGGED_MESSAGE_CASES: "Tag added as per Client and Client Business Unit.",
    NORESULT_TYPEAHEAD_HEADING: "There are no relevant business tags available for this keyword!",
    TOOLTIPS: {
      IPA_TIP_MESSAGE: "IPA tagging is not available as Internal KP type is selected",
      FPA_TIP_MESSAGE: "FPA tagging is not available as Internal KP type is selected",
      POLY_HIERARCHI_CUSTODIAN_MSG: "Shared topic cannot be selected as Custodian, please choose a non-shared topic.",
      POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG: "Shared topic, additional tag will be auto applied/removed.",
      TAG_SPECIFIC_TO_GEO_KNOWLEDGE_TEAM: "Tag added as the content is associated to a specific Geo Knowledge Team, as per case number.",
      TAG_SPECIFIC_FOR_CLIENT_AND_BUSINESS_UNIT: "Tag added as per Client and Client Business Unit.",
      TAG_SPECIFIC_TO_GEO_AUTO_TAG: "Tag added as the content is associated to a specific Geo Knowledge Team, as per case office."
    }
  },
  ALT_TEXTS: {
    ENTER_ICON: "Click here on Enter Icon on search the Material",
    DELETE_ICON: "Delete Icon",
    ENTER_ICON_URL: "Enter Additional Url",
    ADD_URL: "Add url",
    CROSS: "Cross",
    ADD_CASE_CODE: "Add case code",
    SEARCH_ICON: "Search",
    MORE_OPTIONS: "More Options",
    SUGGESTION_LIST_INFOTEXT: "Couldn't find what you were looking for? Try refining your search keyword",
    LOCATION_ICON: "Case Office Location",
    KNOWLEDGE_ASSETS: "Knowledge Assets",
    NO_KNOWLEDGE_ASSETS: "No Knowledge Assets",
    NEXT_STEP: "Proceed to Step 3 - Select Tags",
    CANCEL_MODAL_ALT: "Cancel the Image Picker modal"
  },
};

export default LABELS;
