// constant
import { TAXONOMY } from "redux/constants";
import { createTaxonomyDataPath } from "utils/helpers/helpers";
import CONSTANTS from "globalConstants";

const {
  TBDBS: {
    FPA,
    IPA,
    BCG_INTERNAL
  }
} = CONSTANTS;
// initial state
export const initialState = {
  loading: false,
  error: false,
  setTypeSearchFilterData: [],
  parentTagsData: {},
  allTaxonomyPathObj: {},
  callSlParentTag: false
};
// local variable
export const name = "taxonomy";

// reducer selectors
export const selectors = {
  getLoading: state => state[name].loading,
  getError: state => state[name].error,
  getErrorObject: state => state[name].errorObject,
  getTaxonomyData: (state, taxonomyName) => state[name][taxonomyName] || [],
  getParentTagsData: (state, taxonomyName) => state[name].parentTagsData[taxonomyName] || [],
  getTypeSearchData: state => state[name].setTypeSearchFilterData,
  getTypeSearchDataLoading: state => state[name].loading,
  getAllTaxonomyData: (state) => state[name] || [],
  getAllTaxonomyPathObj: (state) => state[name].allTaxonomyPathObj,
  getCallSlParentTag: (state) => state[name].callSlParentTag
};

export const getFilteredResponse = (data, isShiftFinalLeaf) => {
  const tempArray = data.map(
    (item) => {
      const leafArray = item?.term?.paths[0]?.path;
      if (leafArray) {
        const finalLeaf = leafArray.map((ele) => ele.field);
        !isShiftFinalLeaf && finalLeaf.shift();
        return finalLeaf;
      } else {
        return [];
      }
    }
  );
  return tempArray;
}

const setTaxonomyDataPath = (state) => {
  let taxonomyPathObj = {};
  const { allTaxonomyPathObj } = state;
  if (state[BCG_INTERNAL] && state[FPA] && state[IPA] && allTaxonomyPathObj && Object.keys(allTaxonomyPathObj).length === 0) {
    taxonomyPathObj = createTaxonomyDataPath(
      [...state[BCG_INTERNAL], ...state[FPA], ...state[IPA]]
    );
  }
  return taxonomyPathObj;
}

// reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case TAXONOMY.API_GET_TAXONOMY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorObject: action.payload
      };
    // GET METADATA
    case TAXONOMY.API_GET_TAXONOMY_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorObject: "",
      };

    case TAXONOMY.API_GET_TAXONOMY_SUCCESS:
      const { payload: { res, taxonomyName } } = action;
      const allTaxonomyPathObj = setTaxonomyDataPath(state);
      return {
        ...state,
        loading: false,
        error: false,
        [taxonomyName]: res,
        allTaxonomyPathObj
      };

    case TAXONOMY.API_GET_TYPESEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorObject: action.payload
      };
    // GET METADATA
    case TAXONOMY.API_GET_TYPESEARCH_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorObject: "",
      };
    case TAXONOMY.API_GET_TYPESEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        setTypeSearchFilterData: action.payload
      };
    case TAXONOMY.RESET_ALL_SL_PARENT_TAGS:
      return {
        ...state,
        loading: false,
        error: false,
        parentTagsData: {}
      };
    case TAXONOMY.GET_SL_PARENT_TAGS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorObject: "",
      };
    case TAXONOMY.GET_SL_PARENT_TAGS_SUCCESS:
      const { payload: { data, key, isShiftFinalLeaf } } = action;
      let filterRes = getFilteredResponse(data, isShiftFinalLeaf);
      const { parentTagsData } = state;
      return {
        ...state,
        loading: false,
        error: false,
        parentTagsData: {
          ...parentTagsData,
          [key]: filterRes
        }
      };

    case TAXONOMY.CALL_SL_PARENT_TAG_FLAG:
      const { payload: payloadForCallSlParent } = action;
      return {
        ...state,
        loading: false,
        error: false,
        callSlParentTag: payloadForCallSlParent
      };

    default:
      return state;
  }
}
