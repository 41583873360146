import React, { useEffect, useRef, useState } from "react";
import "./SourcesFilter.scss";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import SearchIcon from "assets/images/SearchIcon.svg";
import Image from "components/shared/Image";
import { RESPONSE_DATA, ROLES, TAXONOMY } from "redux/constants";
import { Checkbox } from '@kdshared/enrich-ui';
import { useDispatch, useSelector } from "react-redux";
import { isInViewport, scrollToTop } from "utils/helpers/helpers";

// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";

const {
    SET_FILTERS,
    RETRIEVAL_API_RESULTS_SUCCESS,
    SOURCE_FILTER_APPLIED,
    CLEAR_TRANSCRIPT_SELECTED,
    COMPANY_FILTER_APPLIED,
    INDUSTRY_FILTER_APPLIED,
    FUNCTION_FILTER_APPLIED,
} = RESPONSE_DATA;

const {
    RESET_ALL_SL_PARENT_TAGS
} = TAXONOMY;

export const SourcesFilter = ({ showSourcesFilterContainer, handleFilterIconClick, lastUserQues }) => {

    const dispatch = useDispatch();
    const filters = useSelector(responseSelector.getFilters);
    const queryResponse = useSelector(responseSelector.getRetrievalResult);
    const companyFilterApplied = useSelector(responseSelector.getCompanyFilterApplied);
    const monthOfInterviewRangeValue = useSelector(responseSelector.getMonthOfInterviewRangeValue);
    const industryFilterApplied = useSelector(responseSelector.getIndustryFilterApplied);
    const functionFilterApplied = useSelector(responseSelector.getFunctionFilterApplied);
    const transcriptResults = queryResponse ? [...queryResponse] : [];
    const actionButtonContainerElementRef = useRef(null);
    let filterRef = useRef();
    const sources = filters.sources;
    const groupedCompanies = filters.groupedCompanies || [];
    const filterData = filters.filteredIndustryTags || [];
    const filterFunctionTags = filters.filteredFunctionTags || [];
    let sourceList = sources.filter(source => source.selected);
    const pageURL = window.location.href;

    useEffect(() => {
        if (showSourcesFilterContainer && actionButtonContainerElementRef.current) {
            const actionButtonContainer = actionButtonContainerElementRef.current;
            let windowScroll = isInViewport(actionButtonContainer);
            let existingScroll = window.scrollY;
            scrollToTop(existingScroll + windowScroll);
        }
    }, [showSourcesFilterContainer]);

    useEffect(() => {
        let filterHandler = (e) => {
            if (!filterRef.current.contains(e.target) && e.target.className !== 'filter sources')
                handleFilterIconClick(false)
        }
        document.addEventListener("mousedown", filterHandler);

        return () => {
            document.removeEventListener("mousedown", filterHandler)
        }
    })

    const handleClearClick = (e) => {
        const newSourceList = [...sources];
        newSourceList.map(status => {
            status.selected = false
        })
        dispatch({ type: SET_FILTERS, payload: { sources: newSourceList } });
        handleApplyClick();
    }
    const handleApplyClick = (e) => {
        sourceList = sourceList.filter(source => source.selected);
        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Transcript_Library_Keyword_Search,
                    previousPageName: PAGES.Transcript_Library_Keyword_Search,
                    pageURL: pageURL
                }
            },
            internal: {
                search: { term: lastUserQues }
            }
        };
        newAdobeData.filterCategory = "Source";
        newAdobeData.filterChecked = sourceList.map(i => i.value).join(" , ");


        ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.FILTER_CLICKS);

        handleFilterIconClick(false);
        //dispatch({ type: QUERY_RESPONSE, payload: { question: latestSearchText, filters, dispatch } });

        transcriptResults.forEach((transcript) => {
            if (sourceList.length > 0) {
                const j = [];
                sourceList.forEach(i => j.push(...i.transcriptIds))
                if (j.indexOf(transcript.meeting_id) !== -1) {
                    transcript.isHideSF = false;
                } else {
                    transcript.isHideSF = true;
                }
            } else {
                transcript.isHideSF = false;
            }
        })


        // const newSourceList = [...sources];
        // newSourceList.map(status => {
        //     if (status.transcriptIds.length === 0 || sourceList.length > 0 && !sourceList.map(i => i.key).includes(status.key))
        //         status.show = false;
        //     else
        //         status.show = true;
        // })
        const selectedTranscripts = transcriptResults.filter(tr => !tr.isHide && !tr.isHideCF && !tr.isHideSF && !tr.isHideIF && !tr.isHideFF);

        const dummySources = {};
        selectedTranscripts.forEach((transcript) => {
            if (dummySources[transcript.vendor_source]) {
                dummySources[transcript.vendor_source].push(transcript.meeting_id);
            } else {
                dummySources[transcript.vendor_source] = [transcript.meeting_id];
            }
        })
        const newSourceList = [...sources];
        newSourceList.map(status => {
            status.value = status.key + " (" + ((dummySources[status.key] && dummySources[status.key].length) || 0) + ")";
            status.transcriptIds = dummySources[status.key] || [];
            status.show = dummySources[status.key] ? dummySources[status.key].length > 0 : false;
        })
        dispatch({ type: SET_FILTERS, payload: { sources: newSourceList } });


        const availableCompanies = []
        selectedTranscripts.forEach((transcript) => {
            const expert_employment_history = transcript.expert_employment_history;
            expert_employment_history && expert_employment_history.length > 0 &&
                expert_employment_history.forEach(eeh => availableCompanies.push(eeh.company));
        })

        const companyList = [];
        const newGroupedCompanies = [...groupedCompanies];
        newGroupedCompanies.map(group => {
            group.children.map(status => {
                const ifMatches = availableCompanies.indexOf(status.key.substring(0, status.key.lastIndexOf(" ("))) > -1;
                status.filterShow = ifMatches;
                if (status.selected && ifMatches) {
                    companyList.push(status.key.substring(0, status.key.lastIndexOf(" (")));
                }
            })
            group.filterShow = group.children.filter(status => status.filterShow).length > 0;
        })
        dispatch({
            type: SET_FILTERS, payload: {
                newGroupedCompanies
            }
        });
        if (companyFilterApplied > 0) {
            dispatch({ type: COMPANY_FILTER_APPLIED, payload: { response: companyList.length } });
        }


        const enabledMonths = [...new Set(selectedTranscripts.map(i => new Date(i.date_of_interview * 1000).getMonth() + "" + new Date(i.date_of_interview * 1000).getFullYear()))];
        //const initialYear = enabledMonths.map(i => i.substring(i.length === 5 ? 1 : 2)).sort()[0];
        dispatch({
            type: SET_FILTERS, payload: {
                response: {
                    enabledMonths
                }
            }
        });

        const allTranscriptSelected = selectedTranscripts.length === transcriptResults.length;

        let newFilterData = [...filterData];
        const selectedMeetingIds = selectedTranscripts.map(tr => tr.meeting_id);
        let tagsSelected = [];
        newFilterData.map((filter) => {
            if (selectedMeetingIds.filter(id => filter.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                filter.filterShow = true;
                if (filter.visible === true) {
                    tagsSelected.push(filter.tagName);
                }
            } else {
                filter.filterShow = false;
                filter.checked = false;
            }
            filter.children && filter.children.length > 0 && filter.children.map(filterLevel1 => {
                if (selectedMeetingIds.filter(id => filterLevel1.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                    filterLevel1.filterShow = true;
                    if (filterLevel1.visible === true) {
                        tagsSelected.push(filterLevel1.tagName);
                    }
                } else {
                    filterLevel1.filterShow = false;
                    filterLevel1.checked = false;
                }
                filterLevel1.children && filterLevel1.children.length > 0 && filterLevel1.children.map(filterLevel2 => {
                    if (selectedMeetingIds.filter(id => filterLevel2.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                        filterLevel2.filterShow = true;
                        if (filterLevel2.visible === true) {
                            tagsSelected.push(filterLevel2.tagName);
                        }
                    } else {
                        filterLevel2.filterShow = false;
                        filterLevel2.checked = false;
                    }
                })
            })
        })
        dispatch({ type: SET_FILTERS, payload: { filteredIndustryTags: newFilterData } });
        industryFilterApplied > 0 && dispatch({ type: INDUSTRY_FILTER_APPLIED, payload: { response: tagsSelected.length } });

        newFilterData = [...filterFunctionTags];
        tagsSelected = [];
        newFilterData.map((filter) => {
            if (selectedMeetingIds.filter(id => filter.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                filter.filterShow = true;
                if (filter.visible === true) {
                    tagsSelected.push(filter.tagName);
                }
            } else {
                filter.filterShow = false;
                filter.checked = false;
            }
            filter.children && filter.children.length > 0 && filter.children.map(filterLevel1 => {
                if (selectedMeetingIds.filter(id => filterLevel1.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                    filterLevel1.filterShow = true;
                    if (filterLevel1.visible === true) {
                        tagsSelected.push(filterLevel1.tagName);
                    }
                } else {
                    filterLevel1.filterShow = false;
                    filterLevel1.checked = false;
                }
                filterLevel1.children && filterLevel1.children.length > 0 && filterLevel1.children.map(filterLevel2 => {
                    if (selectedMeetingIds.filter(id => filterLevel2.transcriptIds.includes(id)).length > 0 || allTranscriptSelected) {
                        filterLevel2.filterShow = true;
                        if (filterLevel2.visible === true) {
                            tagsSelected.push(filterLevel2.tagName);
                        }
                    } else {
                        filterLevel2.filterShow = false;
                        filterLevel2.checked = false;
                    }
                })
            })
        })
        dispatch({ type: SET_FILTERS, payload: { filteredFunctionTags: newFilterData } });
        functionFilterApplied > 0 && dispatch({ type: FUNCTION_FILTER_APPLIED, payload: { response: tagsSelected.length } });

        dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });
        dispatch({ type: RETRIEVAL_API_RESULTS_SUCCESS, payload: { response: transcriptResults } });
        dispatch({ type: SOURCE_FILTER_APPLIED, payload: { response: sourceList } });
        dispatch({ type: RESET_ALL_SL_PARENT_TAGS, payload: { response: "" } });

    }
    const handleStatusClick = (event, data) => {
        const checkboxChecked = data.checked;
        const newSourceList = [...sources];
        newSourceList.map(status => {
            if (status.key === data.value)
                status.selected = checkboxChecked
        })

        dispatch({ type: SET_FILTERS, payload: { sources: newSourceList } });
    };

    return (
        <div ref={filterRef} className="source-filter-container">
            <div className='filter-container'>
                <div className='filter-list-container'>
                    {sources.filter(source => source.show).map((source) => (
                        <div>
                            <div className="filter-item">
                                <Checkbox className="filter-checkbox" label={source.value} value={source.key} checked={source.selected} onClick={handleStatusClick} />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="filter-separator"></div>
                <div className="filter-action-item" ref={actionButtonContainerElementRef}>
                    <button className="btn secondary button" onClick={handleClearClick}>Clear</button>
                    <button className="btn primary button" onClick={handleApplyClick}>Apply Now</button>
                </div>
            </div>
        </div >

    )
};
